

import React, { useEffect, useState, useRef } from "react";
import { Table, Button, message, Spin } from "antd";
import axios from "axios";

const TeamDeletionLog = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const containerRef = useRef(null);
  const [isInitialFetch, setIsInitialFetch] = useState(false);

  // Fetch deleted teams
  const fetchDeletedTeams = async (currentPage) => {
    try {
      setLoading(true);
      const url =
        currentPage === 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?viewAll=true&archive=true`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?viewAll=true&archive=true&page=${currentPage}`;

      const response = await axios.get(url);
      const teams = response.data.data.teamsList;
      const formattedData = teams.map((team) => ({
        key: team._id,
        teamName: team.name,
        deletedAt: new Date(team.modifiedAt).toLocaleString(),
        deletedBy: team.managerId,
      }));

      setDataSource((prev) => [...prev, ...formattedData]);
      setHasMore(response.data.data.moreToCome);
    } catch (error) {
      message.error("Failed to fetch team deletion log. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle infinite scroll
  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setPage((prevPage) => (hasMore ? prevPage + 1 : prevPage));
    }
  };

  // Restore a team
  const handleRestore = async (teamId) => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}/restore`
      );
      message.success("Team restored successfully.");

      // Refresh data after restore
      setPage(0);
      setDataSource([]);
      setHasMore(true);
      setIsInitialFetch(false);
      fetchDeletedTeams(page);
    } catch (error) {
      message.error("Failed to restore the team. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch data on page change or refresh
  useEffect(() => {
    if (!isInitialFetch || page > 0) {
      fetchDeletedTeams(page);
      if (page === 0) setIsInitialFetch(true);
    }
  }, [page]);



  

  const columns = [
    {
      title: <span className="text-[16px] font-semibold leading-[24px] text-[#1B2028]">Team Name</span>,
      dataIndex: "teamName",
      key: "teamName",
      render: (text) => (
        <span className="text-[14px] font-normal leading-[22px] text-[#1B2028] font-sans">{text}</span>
      ),
    },
    {
      title: <span className="text-[16px] font-semibold leading-[24px] text-[#1B2028]">Deleted At</span>,
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (text) => (
        <span className="text-[14px] font-normal leading-[22px] text-[#1B2028] font-sans">{text}</span>
      ),
    },
    {
      title: <span className="text-[16px] font-semibold leading-[24px] text-[#1B2028]">Deleted By</span>,
      dataIndex: "deletedBy",
      key: "deletedBy",
      render: (text) => (
        <span className="text-[14px] font-normal leading-[22px] text-[#1B2028] font-sans">{text}</span>
      ),
    },
    {
      title: <span className="text-[16px] font-semibold leading-[24px] text-[#1B2028]">Action</span>,
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          className="bg-teal-500 text-white border-none hover:bg-teal-600"
          onClick={() => handleRestore(record.key)}
        >
          Restore Team
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col max-w-[1430px] p-4 rounded-lg shadow-md bg-white">
      <h2 className="text-[20px] font-semibold leading-[28px] text-[#192028] mb-4">Team Deletion Log</h2>
      <div
        className="overflow-auto"
        style={{ maxHeight: '552px' }}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={loading && dataSource.length === 0}
          pagination={false}
          bordered={false} // Removing borders
          scroll={{ y: 300 }} // Apply scroll to the table's body
        />
        {loading && page > 0 && (
          <div className="text-center my-2">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamDeletionLog;
