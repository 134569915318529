


import React, { useEffect, useState } from "react";
import Ongoing from "../Components/Todo/Ongoing";
import Completed from "../Components/Todo/Completed";
import TodoHeader from "../Components/Todo/TodoHeader"; // Import the extracted TodoHeader
import { useAuth } from "../authcontext";
import { message } from "antd";
import axiosDocInstance from "../ApiServices/AxiosDocInstance";
import AddTaskModal from "../Services/Modal/AddTaskModal";
import EditTaskModal from "../Services/EditModal/EditTaskModal";

const Todo = () => {
  const { userId, userRole } = useAuth(); // Fetch userId and role from auth context
  const [userTeams, setUserTeams] = useState([]);
  const [ongoingTasks, setOngoingTasks] = useState([]); // Ongoing tasks
  const [completedTasks, setCompletedTasks] = useState([]); // Completed tasks
  const [ongoingPage, setOngoingPage] = useState(0); // Page tracking for ongoing tasks
  const [completedPage, setCompletedPage] = useState(0); // Page tracking for completed tasks
  const [moreOngoingToCome, setMoreOngoingToCome] = useState(true); // Track if more ongoing tasks exist
  const [moreCompletedToCome, setMoreCompletedToCome] = useState(true); // Track if more completed tasks exist
  const [assigneeList, setAssigneeList] = useState([]);
  const [assignerList, setAssignerList] = useState([]);

  const [assigner, setAssigner] = useState(""); // Filter by Assigner (userId)
  const [assignee, setAssignee] = useState(userId || ""); 
  const [team, setTeam] = useState(""); // Filter by Team
  const [onlySelf, setOnlySelf] = useState(null); // Self-assigned filter

  const [availableUsers, setAvailableUsers] = useState([]); // Store users for assigner/assignee
  const [refreshOngoing, setRefreshOngoing] = useState(false);
  const [refreshCompleted, setRefreshCompleted] = useState(false);
  const [isAddTaskModalVisible, setAddTaskModalVisible] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [isEditTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null); // Task to edit

  // const [currentTeams, setCurrentTeams] = useState([]); // Store teams data
  // const [page, setPage] = useState(0); // Current page
  // const [moreToCome, setMoreToCome] = useState(true); // Track if more pages exist
  // const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [triggerNextOngoing, setTriggerNextOngoing] = useState(0); 
  const [triggerNextCompleted, setTriggerNextCompleted] = useState(0); 
  

  const [teamNamePage, setTeamNamePage] = useState(1);
  const [teamNameIsMore, setTeamNameIsMore] = useState(false);

  const handleTaskCreated = () => {
    
    console.log("Task created, toggling taskCreated state");
    setTaskCreated((prev) => !prev); // Toggle taskCreated to trigger refresh
  };

  const handleTaskUpdated = () => {
    setTaskUpdated((prev) => !prev);
  };

  console.log(userTeams);
  console.log({ assigneeList });

  const fetchUserTeams = async () => {
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=0`
      );
      const teams = response.data.data.teams;
      setUserTeams(teams);

      // Extract unique members across all teams
      const members = teams.flatMap((team) => team.members || []);
      // console.log(members);

      const uniqueMembers = Array.from(
        new Map(members.map((m) => [m._id, m])).values() // Ensure unique members by ID
      );
      // console.log({uniqueMembers});
      response.data?.data?.moreToCome && setTeamNameIsMore(response.data.data.moreToCome)
      
      setAssigneeList(uniqueMembers);
      setAssignerList(uniqueMembers);
    } catch (error) {
      console.error("Error fetching user teams:", error);
      message.error("Failed to fetch user teams.");
    }
  };

  const fetchMoreUserTeams = async () => {
    if (!teamNameIsMore) return;
  
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=${teamNamePage}`
      );
      const teams = response.data.data.teams;
  
      setUserTeams((prevTeams) => [...prevTeams, ...teams]); // Append new teams
  
      const members = teams.flatMap((team) => team.members || []);
      const uniqueMembers = Array.from(
        new Map(members.map((m) => [m._id, m])).values()
      );
  
      setAssigneeList((prevAssigneeList) => [
        ...prevAssigneeList,
        ...uniqueMembers,
      ]);
      setAssignerList((prevAssignerList) => [
        ...prevAssignerList,
        ...uniqueMembers,
      ]);
  
      if (response.data?.data?.moreToCome) {
        setTeamNamePage((prevPage) => prevPage + 1);
      } else {
        setTeamNameIsMore(false); // No more teams to fetch
      }
    } catch (error) {
      console.error("Error fetching user teams:", error);
      message.error("Failed to fetch more teams.");
    }
  };
  

  const fetchOngoingTasks = async (reset = false, page = 0) => {
    try {
      const queryParams = buildQueryParams();
      const url =
      page <= 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}${page > 0 ? `&page=${page}` : ""}`;

      const response = await axiosDocInstance.get(url);

      const tasksArray = response.data.data.tasks || [];
      setOngoingTasks((prev) => (reset ? tasksArray : [...prev, ...tasksArray]));
      setMoreOngoingToCome(response.data.data.moreToCome);
      // setOngoingPage(page);
   // Update more ongoing tasks state
    } catch (error) {
      console.error("Error fetching ongoing tasks:", error);
      message.error("Failed to fetch ongoing tasks.");
    }
  };


  // Fetch completed tasks
  const fetchCompletedTasks = async (reset = false, page = 0) => {
    try {
      const queryParams = buildQueryParams();
      const url =
        page === 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true&page=${page}`;

      const response = await axiosDocInstance.get(url);

      const tasksArray = response.data.data.tasks || [];
      setCompletedTasks((prev) => (reset ? tasksArray : [...prev, ...tasksArray]));
      setMoreCompletedToCome(response.data.data.moreToCome);
      // setCompletedPage(page)
       // Update more completed tasks state
    } catch (error) {
      console.error("Error fetching completed tasks:", error);
      message.error("Failed to fetch completed tasks.");
    }
  };





  const buildQueryParams = () => {
    const queryParams = {};
    if (onlySelf =="teamFilter") {
      queryParams.teamFilter = "true"; 
    } 
    else if (onlySelf =="onlySelf") {
      queryParams.onlySelf = "true"; 
    }
    else if (team) {
      queryParams.team = team; // Apply "Team" filter with team ID
    }
    if (assigner) queryParams.assigner = assigner; // Apply assigner filter
    if (assignee) queryParams.assignee = assignee; // Apply assignee filter
    return new URLSearchParams(queryParams).toString();
  };






  // Fetch tasks when filters change
  useEffect(() => {
    // Reset tasks and pages when filters change
    setOngoingTasks([]);
    setCompletedTasks([]);
    setOngoingPage(0);
    setCompletedPage(0);
    
    fetchOngoingTasks(true, 0); // Fetch fresh ongoing tasks
    fetchCompletedTasks(true, 0); // Fetch fresh completed tasks
  }, [assigner, assignee, team, onlySelf, taskCreated, taskUpdated]);


  // Fetch user teams when component mounts or userRole changes
  useEffect(() => {
    console.log("fetchUserTeams useEffect", {});
    if (userRole) {
      fetchUserTeams();
    }
  }, [userRole]);


  // Load next page for ongoing tasks when user scrolls to bottom
  const fetchNextOngoingPage = () => {
    // if (moreOngoingToCome) {
    //   const nextPage = ongoingPage + 1;
    //   setOngoingPage(nextPage);
    //   fetchOngoingTasks(false, nextPage);
    // }
    setTriggerNextOngoing(triggerNextOngoing+1)
  };

  // Load next page for completed tasks when user scrolls to bottom
  const fetchNextCompletedPage = () => {
    // if (moreCompletedToCome) {
    //   const nextPage = completedPage + 1;
    //   setCompletedPage(nextPage);
    //   fetchCompletedTasks(false, nextPage);
    // }
    setTriggerNextCompleted(triggerNextCompleted+1)
  };

  useEffect(() => {
    if (moreOngoingToCome && triggerNextOngoing>0) {
      const nextPage = ongoingPage + 1;
      setOngoingPage(nextPage);
      fetchOngoingTasks(false, nextPage);
    }
  }, [triggerNextOngoing]);
  useEffect(() => {
    if (moreCompletedToCome && triggerNextCompleted>0) {
      const nextPage = completedPage + 1;
      setCompletedPage(nextPage);
      fetchCompletedTasks(false, nextPage);
    }
  }, [triggerNextCompleted]);



  // Handlers for Refresh
  const triggerRefreshOngoing = () => {
    setOngoingPage(0)
    setCompletedPage(0)
    // setRefreshOngoing((prev) => !prev);
    fetchOngoingTasks(true, 0);
    fetchCompletedTasks(true, 0);

  };

  const triggerRefreshCompleted = () => {
    setOngoingPage(0)
    setCompletedPage(0)
    // setRefreshCompleted((prev) => !prev);
    fetchOngoingTasks(true, 0);
    fetchCompletedTasks(true, 0);
  };

  return (
    <>
      <TodoHeader
        assigner={assigner}
        setAssigner={setAssigner}
        assignee={assignee}
        setAssignee={setAssignee}
        team={team}
        setTeam={setTeam}
        onlySelf={onlySelf}
        setOnlySelf={setOnlySelf}
        userTeams={userTeams}
        availableUsers={availableUsers}
        setAvailableUsers={setAvailableUsers}
        userId={userId}
        assigneeList={assigneeList}
        setAssigneeList={setAssigneeList} // Ensure this is included
        assignerList={assignerList}
        setAssignerList={setAssignerList} // Ensure this is included
        fetchMoreUserTeams={fetchMoreUserTeams}

      />

      <AddTaskModal
        isVisible={isAddTaskModalVisible}
        handleCancel={() => setAddTaskModalVisible(false)}
        onTaskCreated={handleTaskCreated} // Pass task created handler
      />

      <EditTaskModal
        visible={isEditTaskModalVisible}
        onClose={() => setEditTaskModalVisible(false)}
        task={selectedTask}
        onTaskUpdated={handleTaskUpdated}
      />

      <div className="p-5">
        <div className="flex flex-col">
          {/* Ongoing tasks */}
          <div className="mb-10 h-3/5">
            <Ongoing
              tasks={ongoingTasks.map((task) => ({
                _id: task?._id,
                name: task?.name,
                description: task?.description,
                dueDate: task?.dueDate,
                priority: task?.priority,
                assigner: task?.assigner?.name || "Unassigned",
                assigner_id: task?.assigner?._id || null,
                assignee: task?.assignee?.name || "Unassigned",
                assignee_id: task?.assignee?._id || null,
                user_id: userId,
                team: task.team?.name || "Unassigned",
                team_id: task.team?._id || null,
              }))}
              setTasks={setOngoingTasks}
              fetchNextPage={fetchNextOngoingPage}
              ongoingRefresh={refreshOngoing}
              triggerCompletedRefresh={triggerRefreshCompleted}
              onTaskCreated={handleTaskCreated}
            // onEditTask={(task) => {
            //   if (task && task?._id) { // Check task validity before proceeding
            //     setSelectedTask(task);
            //     setEditTaskModalVisible(true);
            //   } else {
            //     console.error("Invalid task object:", task);
            //   }
            // }}
            />
          </div>

          {/* Completed tasks */}
          <div className="h-2/5">
            <Completed
              tasks={completedTasks.map((task) => ({
                _id: task?._id,
                name: task?.name,
                description: task?.description,
                dueDate: task?.dueDate,
                priority: task?.priority,
                assigner: task?.assigner?.name || "Unassigned",
                assigner_id: task?.assigner?._id || null,
                assignee: task?.assignee?.name || "Unassigned",
                assignee_id: task?.assignee?._id || null,
                user_id: userId,
                team: task.team?.name || "Unassigned",
              }))}
              setTasks={setCompletedTasks}
              fetchNextPage={fetchNextCompletedPage}
              completedrefresh={refreshCompleted}
              triggerOngoingRefresh={triggerRefreshOngoing}
            // onEditTask={(task) => {
            //   if (task && task?._id) { // Check task validity before proceeding
            //     setSelectedTask(task);
            //     setEditTaskModalVisible(true);
            //   } else {
            //     console.error("Invalid task object:", task);
            //   }
            // }}
            />
          </div>

        </div>
      </div>


    </>

  );
};

export default Todo;
