


// import React, { useState, useEffect } from 'react';
// import { Outlet } from 'react-router-dom';
// import { Spin } from 'antd';
// import TeamsHeader from '../Components/Teams/TeamHeader';
// import Managed from '../Components/Teams/Managed';
// import Joined from '../Components/Teams/Joined';
// import TeamsEmptyState from '../Services/UI/EmptyState/TeamsEmpty';
// import axios from 'axios';
// import { useAuth } from '../authcontext';

// const Teams = () => {
//   const [viewAll, setViewAll] = useState(false); // Control "View All" state
//   const [activeTab, setActiveTab] = useState('managed'); // State to handle tab selection
//   const [teams, setTeams] = useState([]); // State to store the list of teams
//   const [loading, setLoading] = useState(true); // State for loader
//   const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

//   const { userId, sessionToken } = useAuth(); // Access userId and sessionToken from auth context

//   // Function to fetch teams
//   const fetchTeams = async () => {
//     setLoading(true); // Start loading
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${activeTab}`,
//         {
//           headers: {
//             Authorization: `Bearer ${sessionToken}`,
//           },
//         }
//       );
//       setTeams(response.data.data.teamsList || []);
//     } catch (error) {
//       console.error('Error fetching teams:', error);
//       setTeams([]); // Reset teams to an empty array on error
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   // Effect to fetch teams whenever activeTab or sessionToken changes
//   useEffect(() => {
//     fetchTeams();
//   }, [sessionToken, activeTab, userId]);

//   // Function to refresh teams (called after adding a team)
//   const refreshTeams = () => {
//     fetchTeams(); // Re-fetch the teams
//   };

//   const showModal = () => {
//     setIsModalVisible(true); // Show modal when button is clicked
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false); // Close modal
//   };

//   const handleTabChange = (key) => {
//     setActiveTab(key);
//     setViewAll(false); // Reset viewAll state when switching tabs
//   };

//   const renderViewAllOrEmptyState = () => {
//     // if (teams.length > 0) {
//     //   return (
//     //     <button
//     //       className="underline text-[#768090] text-sm"
//     //       style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
//     //       onClick={() => setViewAll(true)} // Trigger setViewAll to show the ViewAll component
//     //     >
//     //       View All
//     //     </button>
//     //   );
//     // }
//     return null;
//   };

//   return (
//     <div>
//       {/* Teams Header */}
//       <TeamsHeader
//         setViewAll={setViewAll}
//         onTabChange={handleTabChange}
//         onAddTeamSuccess={refreshTeams} // Pass the refreshTeams function
//       />

//       {/* Show loader before checking teams size */}
//       {loading ? (
//         <div className="flex justify-center items-center mt-4">
//           <Spin size="large" />
//         </div>
//       ) : teams.length === 0 ? (
//         <TeamsEmptyState /> // Display the empty state when no teams are available
//       ) : activeTab === 'managed' ? (
//         <Managed viewAll={viewAll} type="managed" setViewAll={setViewAll} />
//       ) : (
//         <Joined viewAll={viewAll} type="joined" setViewAll={setViewAll} />
//       )}

//       {/* View All or Empty State */}
//       <div className="mt-4 text-center">{renderViewAllOrEmptyState()}</div>

//       {/* Outlet for routing if needed */}
//       <div>
//         <Outlet />
//       </div>
//     </div>
//   );
// };

// export default Teams;






import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import TeamsHeader from '../Components/Teams/TeamHeader';
import Managed from '../Components/Teams/Managed';
import Joined from '../Components/Teams/Joined';
import TeamsEmptyState from '../Services/UI/EmptyState/TeamsEmpty';
import axios from 'axios';
import { useAuth } from '../authcontext';
import axiosDocInstance from '../ApiServices/AxiosDocInstance';

const Teams = () => {
  const [viewAll, setViewAll] = useState(false); // Control "View All" state
  const [activeTab, setActiveTab] = useState('managed'); // State to handle tab selection
  const [teams, setTeams] = useState([]); // State to store the list of teams
  const [loading, setLoading] = useState(true); // State for loader
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [teamName, setTeamName] = useState("");
  const { userId, sessionToken } = useAuth(); // Access userId and sessionToken from auth context

  // Function to fetch teams
  const fetchTeams = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${activeTab}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setTeams(response.data.data.teamsList || []);
      setTeamName(response.data.data.teamsList.name);
    } catch (error) {
      console.error('Error fetching teams:', error);
      setTeams([]); // Reset teams to an empty array on error
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Effect to fetch teams whenever activeTab or sessionToken changes
  useEffect(() => {
    fetchTeams();
  }, [sessionToken, activeTab, userId]);

  // Function to refresh teams (called after adding a team)
  const refreshTeams = () => {
    fetchTeams(); // Re-fetch the teams
  };

  const showModal = () => {
    setIsModalVisible(true); // Show modal when button is clicked
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setViewAll(false); // Reset viewAll state when switching tabs
  };

  const renderViewAllOrEmptyState = () => {
    // if (teams.length > 0) {
    //   return (
    //     <button
    //       className="underline text-[#768090] text-sm"
    //       style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
    //       onClick={() => setViewAll(true)} // Trigger setViewAll to show the ViewAll component
    //     >
    //       View All
    //     </button>
    //   );
    // }
    return null;
  };

  return (
    <div>
      {/* Teams Header */}
      <TeamsHeader
        setViewAll={setViewAll}
        onTabChange={handleTabChange}
        onAddTeamSuccess={refreshTeams} // Pass the refreshTeams function
        teamName={teamName}
      />

      {/* Show loader before checking teams size */}
      {loading ? (
        <div className="flex justify-center items-center mt-4">
          <Spin size="large" />
        </div>
      ) : teams.length === 0 ? (
        <TeamsEmptyState /> // Display the empty state when no teams are available
      ) : activeTab === 'managed' ? (
        <Managed viewAll={viewAll} type="managed" setViewAll={setViewAll} teamName={teamName} />
      ) : (
        <Joined viewAll={viewAll} type="joined" setViewAll={setViewAll} teamName={teamName}/>
      )}

      {/* View All or Empty State */}
      <div className="mt-4 text-center">{renderViewAllOrEmptyState()}</div>

      {/* Outlet for routing if needed */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Teams;