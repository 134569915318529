// import React, { useState, useEffect } from "react";
// import { Row, Col } from "antd";
// import axios from "axios";
// import DocViewerComponent from "../Components/Versions/DocViewer";
// import VersionCrud from "../Components/Versions/VersionCrud";
// import VersionHistory from "../Components/Versions/VersionHistory";
// import { useParams } from "react-router-dom";
// import { useAuth } from "../authcontext";
// import { JsonEditor } from "json-edit-react"; // Assuming json-edit-react is installed

// const CADFileExtensions = ["image/vnd.dwg", "image/vnd.dxf", "image/vnd.dwf", "image/vnd.rvt"];
// const JSONFileExtensions = ["application/json"];

// const VersionPage = () => {
//   const { docId } = useParams(); // Get document ID from the URL
//   const { userId } = useAuth(); // Get user authentication details

//   const [documentInfo, setDocumentInfo] = useState(null); // Store document info
//   const [selectedVersion, setSelectedVersion] = useState(null); // Store the selected version
//   const [error, setError] = useState(null); // Store error messages
//   const [isCADFile, setIsCADFile] = useState(false);
//   const [isJSONFile, setIsJSONFile] = useState(false);
//   const [jsonData, setJsonData] = useState(null);
//   const [cadReloadKey, setCadReloadKey] = useState(0); // State to force reload

//   // Fetch document information including version history
//   useEffect(() => {
//     const fetchDocumentInfo = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
//         );
//         const data = response.data.data[0]; // Get the first document
//         setDocumentInfo(data); // Set document info
//         setSelectedVersion(data.currentVersion); // Set the current version

//         if (CADFileExtensions.includes(data.docType)) {
//           setIsCADFile(true);
//         }

//         if (JSONFileExtensions.includes(data.docType)) {
//           setIsJSONFile(true);
//           const jsonResponse = await axios.get(
//             `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download`,
//             {
//               params: {
//                 fileName: data.bucketKey,
//                 versionId: data.currentVersion,
//               },
//             }
//           );
//           setJsonData(jsonResponse.data); // Set JSON data
//         }
//       } catch (error) {
//         setError(error.message); // Handle error
//       }
//     };

//     if (docId) {
//       fetchDocumentInfo();
//     }
//   }, [docId, userId]);

//   // Auto-reload CAD file every 3 minutes
//   useEffect(() => {
//     let intervalId;
//     if (isCADFile) {
//       intervalId = setInterval(() => {
//         setCadReloadKey((prevKey) => prevKey + 1); // Increment key to force iframe reload
//       }, 180000); // 180000 ms = 3 minutes
//     }
//     return () => clearInterval(intervalId); // Cleanup interval on component unmount
//   }, [isCADFile]);

//   return (
//     <div style={{ padding: "40px" }}>
//       <Row gutter={16}>
//         {/* Column 2: DocViewer in the center */}
//         <Col span={16}>
//           {documentInfo &&
//             (isCADFile ? (
//               <iframe
//                 key={cadReloadKey} // Use reload key to refresh iframe
//                 src={`//sharecad.org/cadframe/load?url=${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
//                 scrolling="no"
//                 width="100%"
//                 height="105%"
//                 style={{ border: "none" }}
//                 title="CAD Viewer"
//               ></iframe>
//             ) : isJSONFile ? (
//               <div style={{ border: "1px solid #ccc", padding: "10px" }}>
//                 {jsonData && (
//                   <JsonEditor
//                     data={jsonData}
//                     setData={setJsonData}
//                     rootName="JSON Data"
//                     theme={{ rootBackgroundColor: "#f0f2f5" }} // Optional customization
//                   />
//                 )}
//               </div>
//             ) : (
//               <DocViewerComponent
//                 selectedVersion={selectedVersion}
//                 documentInfo={documentInfo}
//               />
//             ))}
//         </Col>

//         {/* Column 3: Split into two rows for VersionCrud and VersionHistory */}
//         <Col span={8}>
//           <Row gutter={[0, 16]}>
//             {/* First row: VersionCrud for managing versions */}
//             <Col span={24}>
//               {documentInfo && (
//                 <VersionCrud
//                   documentInfo={documentInfo}
//                   selectedVersion={selectedVersion}
//                   onVersionUploaded={() => {
//                     // Refresh document info after uploading a new version
//                     axios
//                       .get(
//                         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
//                       )
//                       .then((response) => {
//                         const updatedData = response.data.data[0];
//                         setDocumentInfo(updatedData);
//                         setSelectedVersion(updatedData.currentVersion);
//                       })
//                       .catch((error) => setError(error.message));
//                   }}
//                 />
//               )}
//             </Col>

//             {/* Second row: VersionHistory for listing versions */}
//             <Col span={24}>
//               {documentInfo && (
//                 <VersionHistory
//                   documentInfo={documentInfo}
//                   onSelectVersion={(versionId) => setSelectedVersion(versionId)}
//                 />
//               )}
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       {/* Error handling */}
//       {error && <div>{error}</div>}
//     </div>
//   );
// };

// export default VersionPage;















import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import axios from "axios";
import DocViewerComponent from "../Components/Versions/DocViewer";
import VersionCrud from "../Components/Versions/VersionCrud";
import VersionHistory from "../Components/Versions/VersionHistory";
import { useParams } from "react-router-dom";
import { useAuth } from "../authcontext";
import { JsonEditor } from "json-edit-react"; // Assuming json-edit-react is installed

const CADFileExtensions = ["image/vnd.dwg", "image/vnd.dxf", "image/vnd.dwf", "image/vnd.ifc", "application/x-ifc"];
const RVTFileExtension = "application/vnd.autodesk.revit";
const JSONFileExtensions = ["application/json"];

const VersionPage = () => {
  const { docId } = useParams(); // Get document ID from the URL
  const { userId } = useAuth(); // Get user authentication details

  const [documentInfo, setDocumentInfo] = useState(null); // Store document info
  const [selectedVersion, setSelectedVersion] = useState(null); // Store the selected version
  const [error, setError] = useState(null); // Store error messages
  const [isCADFile, setIsCADFile] = useState(false);
  const [isRVTFile, setIsRVTFile] = useState(false);
  const [isJSONFile, setIsJSONFile] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [cadReloadKey, setCadReloadKey] = useState(0); // State to force reload

  // Fetch document information including version history
  useEffect(() => {
    const fetchDocumentInfo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
        );
        const data = response.data.data[0]; // Get the first document
        setDocumentInfo(data); // Set document info
        setSelectedVersion(data.currentVersion); // Set the current version

        if (CADFileExtensions.includes(data.docType)) {
          setIsCADFile(true);
        }

        if (data.docType === RVTFileExtension) {
          setIsRVTFile(true);
        }

        if (JSONFileExtensions.includes(data.docType)) {
          setIsJSONFile(true);
          const jsonResponse = await axios.get(
            `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download`,
            {
              params: {
                fileName: data.bucketKey,
                versionId: data.currentVersion,
              },
            }
          );
          setJsonData(jsonResponse.data); // Set JSON data
        }
      } catch (error) {
        setError(error.message); // Handle error
      }
    };

    if (docId) {
      fetchDocumentInfo();
    }
  }, [docId, userId]);

  // Auto-reload CAD file every 3 minutes
  useEffect(() => {
    let intervalId;
    if (isCADFile) {
      intervalId = setInterval(() => {
        setCadReloadKey((prevKey) => prevKey + 1); // Increment key to force iframe reload
      }, 180000); // 180000 ms = 3 minutes
    }
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [isCADFile]);

  return (
    <div style={{ padding: "40px" }}>
      <Row gutter={16}>
        {/* Column 2: DocViewer in the center */}
        <Col span={16}>
          {documentInfo &&
            (isCADFile ? (
              <iframe
                key={cadReloadKey} // Use reload key to refresh iframe
                src={`//sharecad.org/cadframe/load?url=${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
                scrolling="no"
                width="100%"
                height="105%"
                style={{ border: "none" }}
                title="CAD Viewer"
              ></iframe>
            ) : isRVTFile ? (
              <iframe
              key={cadReloadKey} 
               scrolling="no"
                src={`//sharecad.org/cadframe/load?url==${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
                width="100%"
                height="105%"
                style={{ border: "none" }}
                title="CAD Viewer"
              ></iframe>
            ) : isJSONFile ? (
              <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                {jsonData && (
                  <JsonEditor
                    data={jsonData}
                    setData={setJsonData}
                    rootName="JSON Data"
                    theme={{ rootBackgroundColor: "#f0f2f5" }} // Optional customization
                  />
                )}
              </div>
            ) : (
              <DocViewerComponent
                selectedVersion={selectedVersion}
                documentInfo={documentInfo}
              />
            ))}
        </Col>

        {/* Column 3: Split into two rows for VersionCrud and VersionHistory */}
        <Col span={8}>
          <Row gutter={[0, 16]}>
            {/* First row: VersionCrud for managing versions */}
            <Col span={24}>
              {documentInfo && (
                <VersionCrud
                  documentInfo={documentInfo}
                  selectedVersion={selectedVersion}
                  onVersionUploaded={() => {
                    // Refresh document info after uploading a new version
                    axios
                      .get(
                        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
                      )
                      .then((response) => {
                        const updatedData = response.data.data[0];
                        setDocumentInfo(updatedData);
                        setSelectedVersion(updatedData.currentVersion);
                      })
                      .catch((error) => setError(error.message));
                  }}
                />
              )}
            </Col>

            {/* Second row: VersionHistory for listing versions */}
            <Col span={24}>
              {documentInfo && (
                <VersionHistory
                  documentInfo={documentInfo}
                  onSelectVersion={(versionId) => setSelectedVersion(versionId)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Error handling */}
      {error && <div>{error}</div>}
    </div>
  );
};

export default VersionPage;
