// import React, { useState } from 'react';
// import { Select, Spin } from 'antd';
// import useDebounceFetch from '../../Services/Utils/Debouncing';

// const { Option } = Select;

// const MemberSearchSelect = ({ members, onChange }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [page, setPage] = useState(0);

//   // Custom hook for debounced member search
//   const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
//     searchTerm,
//     page,
//     setPage
//   );

//   const handleSearch = (value) => {
//     setSearchTerm(value);
//   };

//   const handlePopupScroll = (e) => {
//     const target = e.target;
//     if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
//       fetchMore();
//     }
//   };

//   return (
//     <Select
//       mode="multiple"
//       allowClear
//       style={{ width: '100%' }}
//       placeholder="Search and select members"
//       value={members}
//       onSearch={handleSearch}
//       onChange={onChange}
//       className="mt-1"
//       onPopupScroll={handlePopupScroll}
//       notFoundContent={loading ? <Spin /> : null}
//       filterOption={false}
//     >
//       {results.map((user) => (
//         <Option key={user._id} value={user._id}>
//           {user.email}
//           {user.name ? `${user.name}` : ''}
//         </Option>
//       ))}
//     </Select>
//   );
// };

// export default MemberSearchSelect;





// import React, { useState, useEffect } from 'react';
// import { Select, Spin } from 'antd';
// import useDebounceFetch from '../../Services/Utils/Debouncing';

// const { Option } = Select;

// const MemberSearchSelect = ({ members, onChange }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [page, setPage] = useState(0);
//   const [filteredResults, setFilteredResults] = useState([]);

//   // Custom hook for debounced member search
//   const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
//     searchTerm,
//     page,
//     setPage
//   );

//   useEffect(() => {
//     // Filter out already selected members from the search results
//     const filtered = results.filter((user) => !members.includes(user._id));
//     setFilteredResults(filtered);
//   }, [results, members]);

//   const handleSearch = (value) => {
//     setSearchTerm(value);
//   };

//   const handlePopupScroll = (e) => {
//     const target = e.target;
//     if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
//       fetchMore();
//     }
//   };

//   return (
//     <Select
//       mode="multiple"
//       allowClear
//       style={{ width: '100%' }}
//       placeholder="Search and select members"
//       value={members}
//       onSearch={handleSearch}
//       onChange={onChange}
//       className="mt-1"
//       onPopupScroll={handlePopupScroll}
//       notFoundContent={loading ? <Spin /> : null}
//       filterOption={false}
//     >
//       {filteredResults.map((user) => (
//         <Option key={user._id} value={user._id}>
//             {user.name ? `${user.name}` : ''}
//         </Option>
//       ))}
//     </Select>
//   );
// };

// export default MemberSearchSelect;













import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import useDebounceFetch from '../../Services/Utils/Debouncing';

const { Option } = Select;

const MemberSearchSelect = ({ members, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);

  // Custom hook for debounced member search
  const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
    searchTerm,
    page,
    setPage
  );

  useEffect(() => {
    // Filter out already selected members from the search results
    const filtered = results.filter((user) => !members.includes(user._id));
    setFilteredResults(filtered);
  }, [results, members]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
      fetchMore();
    }
  };

  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Search and select members"
      value={members}
      onSearch={handleSearch}
      onChange={onChange}
      className="mt-1"
      onPopupScroll={handlePopupScroll}
      notFoundContent={
        loading ? (
          <Spin />
        ) : (
          searchTerm && filteredResults?.length === 0 && "No results for your search"
        )
      }
      filterOption={false}
    >
      {filteredResults.map((user) => (
        <Option key={user._id} value={user._id}>
          {user.name ? `${user?.name}` : ''}
        </Option>
      ))}
    </Select>
  );
};

export default MemberSearchSelect;
