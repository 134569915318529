import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Select, Spin, message } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import useDebounceFetch from '../../Services/Utils/Debouncing';

const { Option } = Select;

const EditTeamModal = ({
  visible,
  onClose,
  teamId,
  teamName = '',
  teamDescription = '',
  onEditSuccess,
  comanagerStatus,
}) => {
  const [description, setDescription] = useState(teamDescription);
  const [members, setMembers] = useState([]);
  const [title, setTitle] = useState(teamName);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);

  // Validation state
  const [titleError, setTitleError] = useState('');

  // Update local state when teamName or teamDescription props change
  useEffect(() => {
    if (visible) {
      setDescription(teamDescription || '');
      setTitle(teamName || '');
    }
  }, [teamDescription, teamName, visible]);

  // Custom hook for debounced member search
  const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
    searchTerm,
    page,
    setPage
  );

  const handleSave = async () => {
    // Validate the title
    if (title.length < 2 || title.length > 20) {
      setTitleError('Team name must be between 2 and 20 characters.');
      return;
    }

    // Build the payload only with changed fields
    const data = {};
    if (title !== teamName) data.name = title;
    if (description !== teamDescription) data.description = description;
    if (members.length > 0) data.addMembers = members; // Assuming members contain user IDs

    if (Object.keys(data).length === 0) {
      message.info('No changes detected to save.');
      return;
    }

    try {
      const endpoint = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`;
      const response = await axios.patch(endpoint, data);

      if (response.status === 200) {
        message.success('Team updated successfully');
        if (onEditSuccess) {
          onEditSuccess(); // Call the refresh function
        }
        handleModalClose();
      }
    } catch (error) {
      console.error('Error updating team:', error);
      message.error('Failed to update team');
    }
  };

  // Toggle title editing
  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleSaveTitle = () => {
    if (title.length < 2 || title.length > 20) {
      setTitleError('Team name must be between 2 and 20 characters.');
    } else {
      setTitleError('');
      setIsEditingTitle(false);
    }
  };

  // Update members selection
  const handleMemberChange = (selectedMembers) => {
    setMembers(selectedMembers);
  };

  // Reset all state on modal close
  const handleModalClose = () => {
    setDescription('');
    setMembers([]);
    setSearchTerm('');
    setPage(0);
    setTitle('');
    setTitleError('');
    setIsEditingTitle(false);
    onClose();
  };

  return (
    <Modal
      title={
        <div className="flex items-center justify-center gap-2">
          {comanagerStatus ? (
            <span className="text-center">{title}</span> // Non-editable title for comanager
          ) : (
            isEditingTitle ? (
              <div className="flex items-center gap-2">
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-auto text-center"
                  maxLength={20}
                />
                <CheckOutlined onClick={handleSaveTitle} className="cursor-pointer" />
              </div>
            ) : (
              <div
                className="flex items-center justify-center gap-2 cursor-pointer"
                onClick={handleEditTitle}
              >
                <span className="text-center">{title}</span>
                <EditOutlined className="cursor-pointer" />
              </div>
            )
          )}
        </div>
      }
      visible={visible}
      onCancel={handleModalClose}
      footer={null}
      centered
      destroyOnClose
      width={600}
    >
      <div className="p-5">
        {titleError && <div className="text-red-500 text-sm mb-2">{titleError}</div>}

        {comanagerStatus === false && (
          <>
            {/* Description Section */}
            <div className="text-right text-gray-400 text-sm">{description?.length || 0}/100</div>

            <div className="mb-4">
              <label className="block text-gray-500">Description (Optional)</label>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                maxLength={100}
                className="w-full mt-1 p-2 border rounded-md"
              />
            </div>
          </>
        )}

        {/* Add Members Section */}
        <div className="mb-4">
          <label className="block text-gray-500">Add Members</label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Search and select members"
            value={members}
            onSearch={(value) => setSearchTerm(value)}
            onChange={handleMemberChange}
            className="mt-1"
            onPopupScroll={(e) => {
              const target = e.target;
              if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
                fetchMore();
              }
            }}
            notFoundContent={loading ? <Spin /> : null}
            filterOption={false}
          >
            {results.map((user) => (
              <Option key={user._id} value={user._id}>
                {user.email}
                {user.name ? ` ${user.name}` : ''}
              </Option>
            ))}
          </Select>
          {/* <div className="text-[#1B2028] text-sm mt-2">
            *Team Members will be notified via email
          </div> */}
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={handleModalClose}
            className="bg-gray-300 text-gray-600 px-6 py-2 rounded-md"
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            className="bg-[#41EAD4] text-[#192028] px-6 py-2 rounded-md"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditTeamModal;
