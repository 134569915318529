import React from 'react';
// import Header from '../layouts/header';
import backgroundImage from '../../assets/PNG/Login.png';
import LoginForm from '../Services/Form/LoginForm';
// import ResetForm from '../Services/Form/ResetForm';


import Brixlogo from "../../assets/PNG/Brix-Logo.png";
import spinner from "../../assets/spinner.gif";
import { useAuth } from "../authcontext";

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#F8F9FB",
      zIndex: 9999,
    }}
  >
    {/* Center-top BRIX logo */}
    <div
      style={{
        position: "absolute",
        top: "20px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <img
        src={Brixlogo}
        alt="Brix Logo"
        style={{
          width: "116px",
          height: "44px",
          cursor: "pointer",
        }}
      />
    </div>

    {/* Centered spinner */}
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        src={spinner}
        alt="Loading..."
        style={{
          width: "80px",
          height: "80px",
          animation: "spin 1.5s linear infinite",
        }}
      />
    </div>

    <style>
      {/* {`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `} */}
    </style>
  </div>
);

const Login = () => {
  const { isFetchingUser } = useAuth();
  // const { isFetchingUser } = false;

  return (
    <div className="flex flex-row min-h-screen">


 {isFetchingUser ? (
        <Loader />
      ) : (
        <>
      <div
        className="flex-1 flex-col justify-end items-center flex-shrink-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#F8F9FB',
          maxWidth: '719px',
          maxHeight: '1024px',
          flexShrink: 0,
        }}
      />


      {/* Right side with the form and matching light teal background */}
      <div className="flex-1 flex flex-col items-center justify-center p-8"
        style={{
          backgroundColor: '#F8F9FB',
        }}>
        <LoginForm />

      </div>
      </>
      )}
    </div>
  );
};

export default Login;
