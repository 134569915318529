import React, { useState } from 'react';
import LockIcon from '../../../../assets/SVG/Vault.svg';
import VaultAccess from './VaultAccess';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const CreateVaultPin = () => {
    const [pin, setPin] = useState(['', '', '', '']);
    const [confirmPin, setConfirmPin] = useState(['', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isPinCreated, setIsPinCreated] = useState(false);
    const [showPin, setShowPin] = useState(false); // Toggle for Enter PIN visibility
    const [showConfirmPin, setShowConfirmPin] = useState(false); // Toggle for Confirm PIN visibility

    const handlePinChange = (e, index, isConfirm) => {
        const value = e.target.value;
        if (Number.isNaN(value) || value.length > 1) return;
        if (isConfirm) {
            const newConfirmPin = [...confirmPin];
            newConfirmPin[index] = value;
            setConfirmPin(newConfirmPin);
            if (value && index < 3) document.getElementById(`confirm-pin-${index + 1}`).focus();
            if (!value && index > 0) document.getElementById(`confirm-pin-${index - 1}`).focus();
        } else {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value && index < 3) document.getElementById(`pin-${index + 1}`).focus();
            if (!value && index > 0) document.getElementById(`pin-${index - 1}`).focus();
        }
    };

    const handleSubmit = async () => {
        setErrorMessage('');
        setSuccessMessage('');

        if (pin.join('') !== confirmPin.join('')) {
            setErrorMessage('Pins do not match');
            return;
        }

        const pinValue = pin.join('');
        if (pinValue.length !== 4) {
            setErrorMessage('PIN must be 4 digits');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/pin`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
                },
                body: JSON.stringify({ pin: parseInt(pinValue) }),
            });

            const responseData = await response.json();

            if (response.status === 200) {
                setSuccessMessage('PIN has been successfully set up!');
                setPin(['', '', '', '']);
                setConfirmPin(['', '', '', '']);
                setTimeout(() => setIsPinCreated(true), 1000); // Redirect after success
            } else if (response.status === 400) {
                setErrorMessage(responseData.error.message || 'Invalid request. Please try again.');
            } else if (response.status === 401) {
                setErrorMessage(responseData.error.message || 'Unauthorized. Please log in again.');
            } else if (response.status === 500) {
                setErrorMessage(responseData.error.message || 'Internal server error. Please try later.');
            } else {
                setErrorMessage('Unexpected error. Please try again.');
            }
        } catch (error) {
            setErrorMessage('Network error. Please check your connection.');
        }
    };

    if (isPinCreated) {
        return <VaultAccess />;
    }

    return (
        <div className="flex items-center justify-center p-12 bg-gray-100">
            <div className="flex flex-col items-center bg-white p-10 rounded-lg shadow-lg w-[428px] max-h-[554px]">
                <h2 className="text-2xl font-bold mb-4 text-[#59616E]">Create Vault Pin</h2>
                <div className="icon mb-4">
                    <img src={LockIcon} alt="Lock icon" className="h-12 w-12" />
                </div>
                <p className="text-[#798897] text-center text-sm mb-6">
                    Please enter a 4-digit pin to secure your important documents in the vault.
                </p>

                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

                {/* Enter your PIN Section */}
                <div className="w-full mb-4">
                    <div className="flex justify-between items-center mb-2">
                        <label className="text-left text-[#59616E] font-medium">Enter your PIN</label>
                        <span onClick={() => setShowPin(!showPin)} className="cursor-pointer">
                            {showPin ? (
                                <EyeOutlined style={{ fontSize: '22px', color: '#768090' }} />
                            ) : (
                                <EyeInvisibleOutlined style={{ fontSize: '22px', color: '#768090' }} />
                            )}
                        </span>
                    </div>
                    <div className="flex justify-center space-x-12">
                        {pin.map((digit, index) => (
                            <input
                                key={index}
                                id={`pin-${index}`}
                                type={showPin ? 'text' : 'password'} // Show digits if showPin is true; otherwise, show dots
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handlePinChange(e, index, false)}
                                className="w-12 h-12 border border-[#798897] rounded-lg text-center text-xl text-[#1B2028] focus:outline-none focus:border-[#768090]"
                            />
                        ))}
                    </div>
                </div>

                {/* Confirm your PIN Section */}
                <div className="w-full mb-6">
                    <div className="flex justify-between items-center mb-2">
                        <label className="text-left text-[#59616E] font-medium">Confirm your PIN</label>
                        <span onClick={() => setShowConfirmPin(!showConfirmPin)} className="cursor-pointer">
                            {showConfirmPin ? (
                                <EyeOutlined style={{ fontSize: '22px', color: '#768090' }} />
                            ) : (
                                <EyeInvisibleOutlined style={{ fontSize: '22px', color: '#768090' }} />
                            )}
                        </span>
                    </div>
                    <div className="flex justify-center space-x-12">
                        {confirmPin.map((digit, index) => (
                            <input
                                key={index}
                                id={`confirm-pin-${index}`}
                                type={showConfirmPin ? 'text' : 'password'} // Show digits if showConfirmPin is true; otherwise, show dots
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handlePinChange(e, index, true)}
                                className="w-12 h-12 border border-[#798897] rounded-lg text-center text-xl text-[#1B2028] focus:outline-none focus:border-[#768090]"
                            />
                        ))}
                    </div>
                </div>

                {/* Button section aligned to the right */}
                <div className="flex items-center justify-end w-full gap-3 mt-4">
                    <button className="bg-[#C4CBD8] text-[#1B2028] py-2 px-4 rounded-lg font-medium">Close</button>
                    <button onClick={handleSubmit} className="bg-[#41EAD4] text-[#1B2028] py-2 px-4 rounded-lg font-medium">Save</button>
                </div>
            </div>
        </div>
    );
};

export default CreateVaultPin;