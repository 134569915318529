
// import React, { createContext, useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authenticated, setAuthenticated] = useState(false);
//   const [userRole, setUserRole] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [userEmail, setUserEmail] = useState(null);
//   const [userName, setUserName] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const navigate = useNavigate();

//   // Automatically fetch user details if sessionToken exists
//   useEffect(() => {
//     const sessionToken = localStorage.getItem('sessionToken');
//     if (sessionToken) {
//       axios.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`;
//       fetchUserDetails(true); // Pass true to indicate automatic login
//     }
//   }, []);

//   const login = async (email, password) => {
//     setIsSubmitting(true);
//     try {
//       const response = await axios.post(
//         `https://auth-comm-service.onrender.com/api/v1/auth/login`,
//         { email, password }
//       );

//       const { sessionToken } = response.data.data;
//       localStorage.setItem('sessionToken', sessionToken);
//       axios.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`;
//       await fetchUserDetails();
//       setAuthenticated(true);
//       return { success: true, message: response.data.data.message };
//     } catch (error) {
//       const backendMessage =
//         error?.response?.data?.error?.message || 'An unexpected error occurred.';
//       return { success: false, message: backendMessage };
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const fetchUserDetails = async (isAutoLogin = false) => {
//     try {
//       const userResponse = await axios.get(
//         `https://auth-comm-service.onrender.com/api/v1/auth/loggedInUser`
//       );
//       const { _id, name, email, type } = userResponse.data.data.user;
//       setUserId(_id);
//       setUserName(name);
//       setUserEmail(email);
//       setUserRole(type);
//       setAuthenticated(true);

//       if (isAutoLogin) {
//         navigate('/app/dashboard'); // Redirect to the dashboard upon automatic login
//       }
//     } catch (error) {
//       console.error('Failed to fetch user details:', error);
//       logout();
//     }
//   };

//   const sendPasswordResetLink = async (email) => {
//     try {
//       await axios.post(
//         `https://auth-comm-service.onrender.com/api/v1/auth/forgetPassword`,
//         { email }
//       );
//     } catch (error) {
//       const backendMessage =
//         error?.response?.data?.error?.message || 'An unexpected error occurred.';
//       throw new Error(backendMessage);
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('sessionToken');
//     delete axios.defaults.headers.common['Authorization'];
//     setAuthenticated(false);
//     setUserRole(null);
//     setUserId(null);
//     setUserEmail(null);
//     setUserName(null);
//     navigate('/auth/login'); // Redirect to login page on logout
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         authenticated,
//         userRole,
//         userId,
//         userEmail,
//         userName,
//         login,
//         logout,
//         sendPasswordResetLink,
//         isSubmitting,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);














import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  const unauthenticatedRoutes = ["/reset-pin", "/reset-password", "/forgot-password"];

  // Automatically fetch user details if sessionToken exists
  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
      fetchUserDetails(true); // Pass true to indicate automatic login
    }else{
      setIsFetchingUser(false)
    }
  }, []);

  const login = async (email, password) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/login`,
        { email, password }
      );

      const { sessionToken } = response.data.data;
      localStorage.setItem("sessionToken", sessionToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
      await fetchUserDetails();
      setAuthenticated(true);
      return { success: true, message: response.data.data.message };
    } catch (error) {
      const backendMessage =
        error?.response?.data?.error?.message || "An unexpected error occurred.";
      return { success: false, message: backendMessage };
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchUserDetails = async (isAutoLogin = false) => {
    setIsFetchingUser(true); 

    try {
      const userResponse = await axios.get(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/loggedInUser`
      );
      const { _id, name, email, type } = userResponse.data.data.user;
      setUserId(_id);
      setUserName(name);
      setUserEmail(email);
      setUserRole(type);
      setAuthenticated(true);

      // Redirect to dashboard only if not on an unauthenticated route
      if (isAutoLogin && !unauthenticatedRoutes.includes(location.pathname)) {
        navigate("/app/dashboard");
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      logout();
    } finally {
      setIsFetchingUser(false); 
    }
  };

  const sendPasswordResetLink = async (email) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/forgetPassword`,
        { email }
      );
    } catch (error) {
      const backendMessage =
        error?.response?.data?.error?.message || "An unexpected error occurred.";
      throw new Error(backendMessage);
    }
  };

  const logout = () => {
    localStorage.removeItem("sessionToken");
    delete axios.defaults.headers.common["Authorization"];
    setAuthenticated(false);
    setUserRole(null);
    setUserId(null);
    setUserEmail(null);
    setUserName(null);
    navigate("/auth/login"); // Redirect to login page on logout
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        userRole,
        userId,
        userEmail,
        userName,
        login,
        logout,
        sendPasswordResetLink,
        isSubmitting,
        isFetchingUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
