import React, { useEffect, useState } from "react";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import ProfileDetail from "./ProfileDetail";
import AddCoManagerModal from "../../../Services/Modal/AddCoManagerModal";
import ProfilePic from "../../../Services/UI/ProfilePic";
import { useAuth } from "../../../authcontext";

const KnowTeam = ({
  hasTeams,
  managerId,
  coManagerIds,
  teamId,
  refreshTeams,
  refreshScrollTeam,
}) => {
  const { userId } = useAuth();
  const [localManagerId, setLocalManagerId] = useState(managerId);
  const [localCoManagerIds, setLocalCoManagerIds] = useState(coManagerIds || []);
  const [localTeamId, setLocalTeamId] = useState(teamId);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showProfileDetail, setShowProfileDetail] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showAddCoManagerModal, setShowAddCoManagerModal] = useState(false);

  const isCoManager = localCoManagerIds.includes(userId);

  // Fetch member details based on their IDs
  const fetchMembers = async () => {
    if (!localManagerId && (!localCoManagerIds || localCoManagerIds.length === 0)) return;

    setLoading(true);
    try {
      const memberIds = [localManagerId, ...localCoManagerIds].filter(Boolean);
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
        { memberIds }
      );
      setMembers(response.data.data.memberDetails || []);
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch members when IDs change
  useEffect(() => {
    fetchMembers();
  }, [localManagerId, localCoManagerIds]);

  // Update local state when props change
  useEffect(() => {
    setLocalManagerId(managerId);
  }, [managerId]);

  useEffect(() => {
    setLocalCoManagerIds(coManagerIds || []);
  }, [coManagerIds]);

  useEffect(() => {
    setLocalTeamId(teamId);
  }, [teamId]);

  // Open profile detail
  const handleMemberClick = (member) => {
    setSelectedMember(member);
    setShowProfileDetail(true);
  };

  const handleCloseProfileDetail = () => {
    setShowProfileDetail(false);
    setSelectedMember(null);
  };

  // Add Co-Manager Modal handlers
  const handleAddCoManagerClick = () => {
    setShowAddCoManagerModal(true);
  };

  const handleCloseAddCoManagerModal = () => {
    setShowAddCoManagerModal(false);
  };

  const handleCoManagerAdded = (newCoManagerId) => {
    setLocalCoManagerIds((prevIds) => [...prevIds, newCoManagerId]);
    if (refreshTeams) {
      refreshTeams();
    }
  };

  return (
    <div className="relative w-full">
      {/* Heading Section */}
      <div className="mt-4">
        <h2 className="text-lg font-semibold text-gray-900">Know Your Team</h2>
        <p className="text-sm text-gray-600">
          Get to know your team's strengths and structure.
        </p>
      </div>

      {/* Team Profiles Section */}
      <div className="p-4 bg-white rounded-lg shadow-md mt-7">
        {loading ? (
          <div className="grid grid-cols-2 gap-x-2 gap-y-4">
            {[...Array(4)].map((_, index) => (
              <Skeleton.Avatar key={index} active size={112} shape="circle" />
            ))}
          </div>
        ) : hasTeams && members.length > 0 ? (
          <div className="grid grid-cols-2 gap-x-2 gap-y-4">
            {/* Members */}
            {members.map((member) => (
              <div
                key={member._id}
                className="flex flex-col items-center gap-1 cursor-pointer"
                onClick={() => handleMemberClick(member)}
              >
                <ProfilePic name={member.name} size="xl" />
                <p className="text-base font-medium text-gray-900">
                  {member.name || "Team Member"}
                </p>
              </div>
            ))}

            {/* Add Co-Manager */}
            {!isCoManager && (
              <div
                className="flex flex-col items-center gap-1 cursor-pointer"
                onClick={handleAddCoManagerClick}
              >
                <div className="flex justify-center items-center w-28 h-28 rounded-full border-2 border-teal-400">
                  <PlusOutlined className="text-teal-400 text-3xl" />
                </div>
                <p className="text-base font-medium text-gray-900">
                  Add Co-Manager
                </p>
              </div>
            )}
          </div>
        ) : (
          <p className="text-sm text-gray-600">No team members available.</p>
        )}
      </div>

      {/* ProfileDetail Slide-in Panel */}
      {showProfileDetail && selectedMember && (
        <ProfileDetail
          profile={selectedMember}
          onClose={handleCloseProfileDetail}
          teamId={teamId}
        />
      )}

      {/* Add Co-Manager Modal */}
      {showAddCoManagerModal && (
        <AddCoManagerModal
          visible={showAddCoManagerModal}
          onClose={handleCloseAddCoManagerModal}
          teamId={teamId}
          onCoManagerAdded={handleCoManagerAdded}
          refreshScrollTeam={refreshScrollTeam}
          refreshTeamMembers={refreshScrollTeam}
        />
      )}
    </div>
  );
};

export default KnowTeam;
