import React, { useState, useRef } from 'react';
import ScrollTeam from './Common/ScrollTeam';
import KnowTeam from './Common/KnowTeam';
import TeamMembers from './Common/TeamMembers';
import ViewAll from './Common/ViewAll';

const Managed = ({ viewAll, setViewAll }) => {
  const [memberIds, setMemberIds] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [coManagerIds, setCoManagerIds] = useState([]);
  const [teamId, setTeamId] = useState(null);

  // Use useRef to hold the refresh function reference
  const refreshScrollTeam = useRef(null);

  const handleTeamSelect = ({ memberIds, managerId, coManagerIds, teamId }) => {
    setTeamId(teamId);
    setMemberIds(memberIds);
    setManagerId(managerId);
    setCoManagerIds(coManagerIds);
  };

  const triggerScrollTeamRefresh = () => {
    if (refreshScrollTeam.current) {
      refreshScrollTeam.current(); // Call the ScrollTeam refresh function
      console.log("Refresh ScrollTeam triggered");
    }
  };

  return (
    <div>
      {!viewAll ? (
        <div className="p-4">
          <div>
            <ScrollTeam
              type="managed"
              onTeamSelect={handleTeamSelect}
              setViewAll={setViewAll}
              refreshScrollTeam={refreshScrollTeam} // Pass the ref to ScrollTeam
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-2">
              <TeamMembers
                memberIds={memberIds}
                managerId={managerId}
                coManagerIds={coManagerIds}
                teamId={teamId}
                type="managed"
                refreshScrollTeam={triggerScrollTeamRefresh} // Use the trigger function
              />
            </div>
          </div>
        </div>
      ) : (
        <ViewAll type="managed" />
      )}
    </div>
  );
};

export default Managed;
