import React, { useState, useEffect, useRef } from "react";
import {
  FilterOutlined,
  DownOutlined,
  FileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../authcontext";

// Reusable FilterSelect component
const FilterSelect = ({ label, value, onChange, options }) => {


  return (
    <div className="relative w-[150px]">
      <select
        className="w-full px-4 py-2 border rounded-md bg-white text-black appearance-none cursor-pointer"
        value={value}
        onChange={onChange}
      >
        <option value="">{label}</option>
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      <DownOutlined className="absolute right-2 top-1/2 transform -translate-y-1/2" />
    </div>
  );
};


const FilterSelectTeam = ({ label, value, onChange, options, onScrollEnd, isScrollable }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 1 && onScrollEnd) {
      onScrollEnd(); // Trigger the callback when scrolled to the bottom
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="relative"
      ref={containerRef}
      style={{ width: "auto", minWidth: "200px" }}
    >
      <div
        className="px-4 py-2 border rounded-md bg-white text-black cursor-pointer flex justify-between items-center"
        onClick={toggleDropdown}
        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        <span>
          {value ? options.find((opt) => opt._id === value)?.name || label : label}
        </span>
        <DownOutlined />
      </div>
      {isOpen && (
        <div
          className="absolute z-50 bg-white border rounded-md shadow-lg mt-2"
          style={{
            maxHeight: isScrollable ? "150px" : "auto",
            overflowY: isScrollable ? "auto" : "visible",
            width: "100%",
          }}
          onScroll={isScrollable ? handleScroll : undefined}
          ref={dropdownRef}
        >
          <div>
            <div
              key=""
              className="px-4 py-2 hover:bg-[#D1F8F0] cursor-pointer"
              onClick={() => {
                onChange({ target: { value: "" } });
                setIsOpen(false);
              }}
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {label}
            </div>
            {options.map((option) => (
              <div
                key={option._id}
                className="px-4 py-2 hover:bg-[#D1F8F0] cursor-pointer"
                onClick={() => {
                  onChange({ target: { value: option._id } });
                  setIsOpen(false);
                }}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Reusable DropdownItem component
const DropdownItem = ({ icon: Icon, label, onClick }) => (
  <div
    className="flex items-center px-4 py-2 hover:bg-[#D1F8F0] text-[#1B2028] rounded-lg cursor-pointer"
    onClick={onClick}
  >
    <Icon className="text-[#798897] mr-2" />
    <span>{label}</span>
  </div>
);

const TodoHeader = ({
  assigner,
  setAssigner,
  assignee,
  setAssignee,
  team,
  setTeam,
  onlySelf,
  setOnlySelf,
  userTeams,
  availableUsers,
  setAvailableUsers,
  userId,
  assigneeList,
  assignerList,
  setAssigneeList,
  setAssignerList,
  fetchMoreUserTeams,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isFilterSelfAssigned, setFilterSelfAssigned] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const filterButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    if (!showDropdown && filterButtonRef.current) {
      const buttonRect = filterButtonRef.current.getBoundingClientRect();
      const dropdownWidth = 199;
      setDropdownPosition({
        top: buttonRect.bottom + window.scrollY,
        left: buttonRect.right - dropdownWidth + window.scrollX,
      });
    }
    setShowDropdown(!showDropdown);
  };

  // Close dropdown on outside click
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !filterButtonRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  const handleTeamAssigned = () => {
    setFilterSelfAssigned(false)
    setOnlySelf('teamFilter'); // Disable the "Self Assigned" filter
    setTeam("teamFilterActive"); // Indicate that the team filter is active
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (!team) {
      // Default behavior when no team is selected
      setAvailableUsers([{ _id: userId, name: "Self" }]);
      setAssigneeList([{ _id: userId, name: "Self" }]);
      setAssignerList([{ _id: userId, name: "Self" }]);
    } else {
      // Populate assigner and assignee lists based on selected team
      const selectedTeam = userTeams.find((t) => t._id === team);
      if (selectedTeam) {
        const members = selectedTeam.members.filter((m) => m.name);
        const uniqueMembers = members.map((m) => ({ _id: m._id, name: m.name }));
        setAvailableUsers(uniqueMembers);
        setAssigneeList(uniqueMembers);
        setAssignerList(uniqueMembers);
      } else {
        setAvailableUsers([]);
        setAssigneeList([]);
        setAssignerList([]);
      }
    }
  }, [team, userTeams, userId]);

  // Self-assigned filter logic
  const handleSelfAssigned = () => {
    setFilterSelfAssigned(true)
    setAssigner(userId);
    setAssignee(userId);
    setTeam("");
    setOnlySelf('onlySelf');
    setAssigneeList([{ _id: userId, name: "Self" }]);
    setAssignerList([{ _id: userId, name: "Self" }]);
    setShowDropdown(!showDropdown);
  };

  return (
    <div>
      {/* Header with Filter Button */}
      <div className="flex justify-between items-center mb-4 p-4">
        <h1 className="text-[20px] font-semibold leading-[28px] text-[#192028]">
          To-Do List
        </h1>
        <button
          ref={filterButtonRef}
          className="relative z-10 flex items-center border border-[#D9DEE7] rounded-md text-[#1B2028] bg-white h-[40px] w-[150px] justify-center"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={showDropdown}
        >
          <span className="flex-grow text-center pl-4">Filter</span>
          <span className="flex items-center justify-center border border-[#41EAD4] rounded-md h-[40px] w-[40px] bg-white">
            <FilterOutlined className="text-[#41EAD4] text-[14px]" />
          </span>
        </button>
      </div>

      {/* Filters Section */}
      {/* <div className="flex items-center justify-between p-4 max-w-[1130px] max-h-[1124px]"> */}
      <div className="flex items-center justify-center p-4 max-w-[1130px] gap-x-16  mx-auto">

        <FilterSelect
          label="Assigner"
          value={assigner}
          onChange={(e) => setAssigner(e.target.value)}
          options={assignerList || []}
        />
        <FilterSelect
          label="Assignee"
          value={assignee}
          onChange={(e) => setAssignee(e.target.value)}
          options={assigneeList || []}
        />
        {!isFilterSelfAssigned ?
          <FilterSelectTeam
            label="Team"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
            options={userTeams.map((t) => ({ _id: t._id, name: t.name }))}
            onScrollEnd={fetchMoreUserTeams}
            isScrollable={true} // Fetch more data when scrolled to the bottom
          />
          : (
            <div className="relative">

            </div>)
        }


      </div>
      <hr className="border-t border-[#E0E0E0] mb-4" />

      {/* Dropdown for Filter Options */}
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute z-50 flex flex-col p-1 rounded-lg bg-white shadow-lg w-[199px]"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <DropdownItem
            icon={FileOutlined}
            label="Self Assigned"
            onClick={handleSelfAssigned}
          />
          <DropdownItem
            icon={TeamOutlined}
            label="Team Assigned"
            onClick={handleTeamAssigned}
          />
        </div>
      )}
    </div>
  );
};


export default TodoHeader;