import React, { useState } from "react";
import { Modal, Button, Input, Form, Select, message } from "antd";
import { EditOutlined, DownOutlined } from "@ant-design/icons";
import { useAuth } from "../../authcontext";
import TeamSuccessSvg from "../../../assets/SVG/Success.svg";
import TeamErrorSvg from "../../../assets/SVG/Error.svg";
import MemberSearchSelect from "../Utils/MemberSearchSelect";
import axios from "axios";

const { TextArea } = Input;

const AddTeamModal = ({
  isModalVisible,
  handleCancel,
  fetchTeams,
  onAddTeamSuccess,
  refreshTeams,
}) => {
  const { userId } = useAuth();
  const [status, setStatus] = useState("idle");
  const [form] = Form.useForm();
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [members, setMembers] = useState([]);
  const [coManagers, setCoManagers] = useState([]);
  const [department, setDepartment] = useState("");
  const [teamName, setTeamName] = useState("Create New Team");
  const [isEditingName, setIsEditingName] = useState(false);
  const [teamNameCharCount, setTeamNameCharCount] = useState(0);

  const clearCache = () => {
    form.resetFields();
    setDescriptionCount(0);
    setMembers([]);
    setCoManagers([]);
    setDepartment("");
    setTeamName("Create New Team");
    setIsEditingName(false);
    setStatus("idle");
  };

  const handleCancelLocal = () => {
    clearCache();
    handleCancel();
  };

  const handleDescriptionChange = (e) => {
    setDescriptionCount(e.target.value.length);
  };

  const handleSubmit = async (values) => {
    const isValidObjectId = (id) => /^[a-f\d]{24}$/i.test(id);

    if(teamName==="Create New Team")
    {
      message.error("Team name can not be empty");
      return;
    }

    // Validate Team Name
    if (!teamName || teamName.length < 2 || teamName.length > 20) {
      message.error("Team name must be between 2 and 20 characters.");
      return;
    }

    // Validate Description
    if (
      values.description &&
      (values.description.length < 2 || values.description.length > 100)
    ) {
      message.error("Description must be between 2 and 100 characters.");
      return;
    }

    // Validate Department
    if (department && (department.length < 2 || department.length > 15)) {
      message.error("Department name must be between 2 and 15 characters.");
      return;
    }

    // Validate Co-Managers
    if (
      coManagers.some((id) => !isValidObjectId(id)) ||
      coManagers.includes(userId)
    ) {
      message.error(
        "Invalid co-manager IDs or co-manager includes the logged-in user."
      );
      return;
    }

    // Validate Members
    if (members.some((id) => !isValidObjectId(id))) {
      message.error("Invalid member IDs.");
      return;
    }

    // Construct payload with only non-null or non-empty fields
    const payload = {
      name: teamName,
      ...(values.description && { description: values.description }),
      ...(department && { department }),
      ...(members.length > 0 && { members }),
      ...(coManagers.length > 0 && { comanagerId: coManagers }),
    };

    try {
      setStatus("loading");
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setStatus("success");
      message.success("Team created successfully!");
      if (refreshTeams) {
        refreshTeams(); // Trigger the refresh of teams in the ScrollTeam component
      }
      handleCancel(); // Close the modal
    } catch (error) {
      console.error("Error creating team:", error);
      setStatus("error");
      message.error("Failed to create the team. Please try again.");
    }
  };

  const renderForm = () => (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack items vertically
          alignItems: "flex-start", // Align items to the left
          gap: "10px", // Space between title, icon, and character counter
          // border: "1px solid #D2D7DE",
          padding: "10px",
        }}
      >
        <div className="flex flex-col text-left">
          {isEditingName ? (
            <Input
              value={teamName}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 20) {
                  setTeamName(newValue);
                  setTeamNameCharCount(newValue.length);
                }
              }}
              onBlur={() => setIsEditingName(false)}
              maxLength={20}
              autoFocus
              className="w-[300px] text-[30px] font-semibold text-[#59616E] focus:outline-none focus:ring-0 border-none"
            />
          ) : (
            <div className="flex flex-col gap-1">
             <div
  className="flex items-center gap-2 cursor-pointer"
  onClick={() => {
    if (teamName === "Create New Team") {
      setTeamName("");
    }
    setIsEditingName(true);
  }}
>
  <h2 className="text-[30px] font-semibold text-[#1E2021] m-0">
    {teamName}
  </h2>
  <EditOutlined className="text-[#59616E] w-[32px] h-[32px]" />
</div>


              {/* Character Counter */}
              <div className="text-left text-[#768090] font-semibold text-[14px] leading-[22px]">
                {teamNameCharCount}/20
              </div>
            </div>
          )}
        </div>
      </div>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="description"
          label={
            <label className="flex items-center text-[#576271] font-semibold text-[16px] leading-[24px]">
              Description
              <span className="ml-1 text-[#8B96AB] font-normal text-[14px] leading-[22px]">
                (Optional)
              </span>
            </label>
          }
          rules={[
            {
              max: 100,
              message: "Description must be at most 100 characters.",
            },
            { min: 2, message: "Description must be at least 2 characters." },
          ]}
        >
          <TextArea
            placeholder="Enter description"
            maxLength={100}
            onChange={handleDescriptionChange}
            autoSize={{ minRows: 2, maxRows: 4 }}
            style={{
              borderRadius: "8px",
              border: "1px solid #D9DEE7",
              paddingRight: "40px", // Add padding to make space for the counter
            }}
          />
        </Form.Item>

        <Form.Item
          name="members"
          label={
            <label className="flex items-center text-[#576271] font-semibold text-[16px] leading-[24px]">
              Add Members
            </label>
          }
        >
          <MemberSearchSelect members={members} onChange={setMembers} />
        </Form.Item>

        <div style={{ display: "flex", gap: "24px", marginBottom: "20px" }}>
          <Form.Item
            name="comanagerId"
            label={
              <label className="flex items-center text-[#576271] font-semibold text-[16px] leading-[24px]">
                Co-Manager
              </label>
            }
            extra={
              <span className="text-[#1B2028] font-normal text-[12px] leading-[20px] mt-8">
                {/* *Team Members will be notified via email */}
              </span>
            }
            style={{ flex: 1 }}
          >
            <MemberSearchSelect members={coManagers} onChange={setCoManagers} />
          </Form.Item>

          <Form.Item
            name="department"
            label={
              <label className="flex items-center text-[#576271] font-semibold text-[16px] leading-[24px]">
                Department{" "}
                <span className="text-[#A0AEC0] font-normal text-[14px] leading-[20px]">
                  (Optional)
                </span>
              </label>
            }
            style={{ flex: 1 }}
          >
            <Select
              placeholder="Select or add a department"
              onChange={setDepartment}
              value={department}
              suffixIcon={<DownOutlined />}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      borderTop: "1px solid #f0f0f0",
                    }}
                  >
                    <Input
                      placeholder="Add a new department"
                      value={department}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 20) {
                          setDepartment(value);
                        }
                      }}
                      onPressEnter={() => {
                        if (department.length >= 2 && department.length <= 20) {
                          message.success(
                            `Added new department: ${department}`
                          );
                          form.setFieldsValue({ department });
                        } else {
                          message.error(
                            `Department name must be between 2 and 20 characters`
                          );
                        }
                      }}
                      style={{ marginRight: "8px", flex: 1 }}
                    />
                    <Button
                      type="link"
                      onClick={() => {
                        if (department.length >= 2 && department.length <= 20) {
                          message.success(
                            `Added new department: ${department}`
                          );
                          form.setFieldsValue({ department });
                        } else {
                          message.error(
                            `Department name must be between 2 and 20 characters`
                          );
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </>
              )}
              style={{ border: "1px solid #D9DEE7", borderRadius: "8px" }}
            >
              <Select.Option value="Human Resources">
                Human Resources
              </Select.Option>
              <Select.Option value="Finance">Finance</Select.Option>
              <Select.Option value="Marketing">Marketing</Select.Option>
              <Select.Option value="Sales">Sales</Select.Option>
              <Select.Option value="Technology">Technology</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Button
            onClick={handleCancelLocal}
            style={{
              backgroundColor: "#E1E5EC",
              color: "#59616E",
              borderRadius: "8px",
              border: "1px solid #E1E5EC",
              width: "318px",
              height: "40px",
            }}
          >
            Close
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={status === "loading"}
            style={{
              backgroundColor: "#41EAD4",
              color: "#192028",
              borderRadius: "8px",
              border: "1px solid #41EAD4",
              width: "318px",
              height: "40px",
            }}
          >
            Create
          </Button>
        </div>
      </Form>
    </>
  );

  const renderSuccess = () => (
    <div
      style={{
        display: "flex",
        maxWidth: "539px",
        maxHeight: "396px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <img src={TeamSuccessSvg} alt="Success" />
      <h2 style={{ color: "#192028", fontSize: "20px", fontWeight: "600" }}>
        Team Created Successfully
      </h2>
      <Button
        type="primary"
        style={{
          backgroundColor: "#41EAD4",
          color: "#192028",
          borderRadius: "8px",
          padding: "8px 24px",
          border: "none",
        }}
        onClick={handleCancelLocal}
      >
        Go to Teams
      </Button>
    </div>
  );

  const renderError = () => (
    <div
      style={{
        display: "flex",
        maxWidth: "539px",
        maxHeight: "396px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <img src={TeamErrorSvg} alt="Error" />
      <p
        style={{
          color: "#A6B6C6",
          fontSize: "12px",
          lineHeight: "28px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        An error occurred while creating the team. <br />
        Please check the following and try again.
      </p>
      <Button
        type="primary"
        style={{
          backgroundColor: "#41EAD4",
          color: "#192028",
          borderRadius: "8px",
          padding: "8px 15px",
          border: "none",
        }}
        onClick={() => setStatus("idle")}
      >
        Try Again
      </Button>
    </div>
  );

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancelLocal}
      footer={null}
      centered
      width={status === "idle" ? 900 : 539}
      style={{
        minHeight: status === "idle" ? "631px" : "396px",
        borderRadius: "8px",
        padding: "90px",
      }}
      className="custom-modal"
      afterClose={clearCache}
    >
      {status === "idle" && renderForm()}
      {status === "success" && renderSuccess()}
      {status === "error" && renderError()}
    </Modal>
  );
};

export default AddTeamModal;
