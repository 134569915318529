import React, { useState } from 'react';
import { Modal, Button, message, Spin } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons'; // Import the icon

const RemoveFromTeamModal = ({
  visible,
  onClose,
  teamId,
  userId,
  userName,
  refreshTeamMembers,
}) => {
  const [loading, setLoading] = useState(false); // Loading state

  const handleRemoveMember = async () => {
    setLoading(true); // Start loading
    try {
      const apiUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`;
      const requestBody = {
        removeMembers: [userId], // Specify the userId to be removed
      };

      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`, // Authorization token
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to remove member');
      }

      // Show success message
      message.success(`${userName} has been removed from the team.`);
      onClose(); // Close the modal
      refreshTeamMembers?.(); // Optionally refresh the team member list
    } catch (error) {
      message.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ padding: 0 }}
    >
      <div className="flex flex-col items-center gap-3 p-6 w-full max-w-md bg-white">
        <div className="flex items-center justify-center gap-2 mb-2">
          <UserDeleteOutlined style={{ fontSize: '20px', color: '#59616E' }} />
          <h2 className="text-lg font-semibold text-gray-900">Remove {userName}</h2>
        </div>

        <p className="text-base leading-6 text-center text-gray-400 mb-8">
          Are you sure you want to remove {userName}?
          <br />
          They will no longer be part of this team.
        </p>

        <div className="flex justify-end gap-4 w-full">
          <Button
            onClick={onClose}
            className="w-24 h-10 bg-gray-100 text-gray-500 border-none rounded-md"
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={handleRemoveMember}
            className="w-24 h-10 bg-[#FF6F61] text-white border-none rounded-md"
            disabled={loading}
          >
            {loading ? <Spin size="small" /> : 'Remove'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveFromTeamModal;