import React, { useState } from "react";
import { Modal, Upload, message, Progress } from "antd";
import {
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import UploadProcess from "../../../assets/SVG/UploadProcess.png";
import UploadSuccessIcon from "../../../assets/SVG/UploadSuccessfull.svg";
import ErrorImage from "../../../assets/SVG/Error.png";

const AddDocModal = ({ isVisible, handleCancel, userId }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  // Infers file MIME type based on its extension
  const inferFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "dwg":
        return "image/vnd.dwg";
      case "dxf":
        return "image/vnd.dxf";
      case "dwf":
         return "image/vnd.dwf";
      case "rvt":
        return "application/vnd.autodesk.revit";  
      case "ifc":
          return "application/x-ifc"; // or "model/ifc"
                
      default:
        return "application/octet-stream";
    }
  };

  // Handles file selection and starts the upload process
  const handleFileChange = (info) => {
    const selectedFile = info.file.originFileObj;

    if (selectedFile) {
      const inferredType =
        selectedFile.type || inferFileType(selectedFile.name);

      console.log("Selected File Name:", selectedFile.name);
      console.log("Inferred File Type:", inferredType);

      const updatedFile = new File([selectedFile], selectedFile.name, {
        type: inferredType,
        lastModified: selectedFile.lastModified,
      });

      setFile(updatedFile);
      setUploadStatus("uploading");
      setProgress(0);

      // Start uploading the file
      uploadFile(updatedFile);
    }
  };

  // Uploads the file using Axios
  const uploadFile = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", userId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        setUploadStatus("success");
        message.success("File uploaded successfully");
      } else {
        setUploadStatus("error");
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("error");
      message.error("File upload failed");
    } finally {
      setLoading(false);
      setFile(null);
    }
  };

  // Resets the state when a file is deleted
  const handleDeleteFile = () => {
    setFile(null);
    setUploadStatus("idle");
    setProgress(0);
  };

  // Dynamically renders content based on the upload status
  const renderUploadContent = () => {
    if (uploadStatus === "idle") {
      return (
        <div style={{ padding: "60px", textAlign: "center" }}>
          <Upload
            onChange={handleFileChange}
            // showUploadList={false}
            // accept=".pdf,.txt,.docx,.dwg,.dxf"
          >
            <UploadOutlined
              style={{ fontSize: "64px", color: "#41EAD4", cursor: "pointer" }}
            />
          </Upload>
          <h2 style={{ color: "#59616E", fontSize: "24px" }}>
            Upload Your File
          </h2>
          <p style={{ color: "#768090" }}>
          Upload / Drag & Drop your file
          </p>
          <p>
          Max file size 10MB
          </p>
        </div>
      );
    } else if (uploadStatus === "uploading") {
      return (
        <div style={{ textAlign: "center" }}>
          <img src={UploadProcess} alt="Uploading" style={{ width: "120px" }} />
          <h3>{file ? file.name : "Document Name"}</h3>
          <Progress percent={progress} strokeColor="#41EAD4" />
        </div>
      );
    } else if (uploadStatus === "success") {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={UploadSuccessIcon}
            alt="Upload Successful"
            style={{ width: "80px" }}
          />
          <h3>Upload Successful!</h3>
        </div>
      );
    } else if (uploadStatus === "error") {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={ErrorImage}
            alt="Upload Failed"
            style={{ width: "132px" }}
          />
          <h3>Upload Failed</h3>
        </div>
      );
    }
  };

  return (
    <Modal
      title={null}
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={900}
      bodyStyle={{ textAlign: "center" }}
    >
      {renderUploadContent()}
    </Modal>
  );
};

export default AddDocModal;
