
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TeamCard from '../../../Services/UI/TeamCard';
import EmptyTeamCard from '../../../Services/UI/EmptyState/EmptyTeamCard';
import TeamDetails from './TeamDetails';
import { useAuth } from '../../../authcontext';

const ViewAll = ({ type }) => {
  const { userId } = useAuth(); // Fetch userId from auth context
  const [teams, setTeams] = useState([]); // Store list of teams
  const [page, setPage] = useState(2); // Pagination state
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // Check if more teams are available
  const containerRef = useRef(null); // Reference for scroll container
  const [selectedTeam, setSelectedTeam] = useState(null); // Selected team state
  const [testVariable, setTestVariable] = useState(""); // For debugging

  // Fetch initial teams
  const fetchInitialTeams = async () => {
    setLoading(true);
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}&viewAll=true`
      );
      const initialTeams = response1.data.data.teamsList;

      const response2 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}&viewAll=true&page=1`
      );
      const pageOneTeams = response2.data.data.teamsList;

      setTeams([...initialTeams, ...pageOneTeams]);
      if (pageOneTeams.length === 0) setHasMore(false);
    } catch (error) {
      console.error('Error fetching initial teams:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch teams by page (pagination)
  const fetchTeamsByPage = async (pageNum) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}&viewAll=true&page=${pageNum}`
      );
      const fetchedTeams = response.data.data.teamsList;

      if (fetchedTeams.length === 0) {
        setHasMore(false);
      } else {
        setTeams((prev) => [...prev, ...fetchedTeams]);
      }
    } catch (error) {
      console.error('Error fetching more teams:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    if (userId) fetchInitialTeams();
  }, [userId, type]);

  // Scroll handling for infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        container &&
        container.scrollHeight - container.scrollTop <= container.clientHeight + 50 &&
        hasMore &&
        !loading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loading]);

  // Fetch teams on pagination
  useEffect(() => {
    if (page > 2) fetchTeamsByPage(page);
  }, [page]);

  // Handle team click
  const handleTeamClick = (team) => {
    setSelectedTeam({ ...team });
    setTestVariable("delhi");
  };

  // Render loading placeholders
  const renderLoadingPlaceholders = () => (
    <div className="grid grid-cols-3 gap-4">
      {Array.from({ length: 9 }).map((_, index) => (
        <div
          key={index}
          className="w-72 h-52 bg-gray-200 rounded-lg animate-pulse"
        ></div>
      ))}
    </div>
  );

  return (
    <div ref={containerRef} className="h-[80vh] overflow-y-scroll p-5">
      {/* Conditionally render TeamDetails or Team List */}
      {selectedTeam && selectedTeam._id ? (
        <TeamDetails
          memberIds={selectedTeam?.members}
          managerId={selectedTeam?.managerId}
          coManagerIds={selectedTeam?.comanagerId || []}
          teamId={selectedTeam?._id} // Fixed teamId reference
          type={type}
          teamName={selectedTeam?.name}
        />
      ) : (
        <>
          {/* Display Teams */}
          {teams.length > 0 ? (
            <div className="grid grid-cols-3 gap-4">
              {teams.map((team) => (
                <div
                  key={team._id}
                  onClick={() => handleTeamClick(team)}
                  className="cursor-pointer"
                >
                  <TeamCard team={team} viewAll />
                </div>
              ))}
            </div>
          ) : (
            // Empty State
            !loading && <EmptyTeamCard message="No teams available to display." />
          )}

          {/* Loading Placeholders */}
          {loading && renderLoadingPlaceholders()}

          {/* Loading Message */}
          {loading && <div className="text-center py-4">Loading more teams...</div>}
        </>
      )}

    </div>
  );
};

export default ViewAll;
              