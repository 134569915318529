import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { UserAddOutlined, CloseOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";
import { useAuth } from "../../authcontext";
import MemberSearchSelect from "../Utils/MemberSearchSelect";

const AddNewTeamMemberModal = ({
  visible,
  onClose,
  type,
  teamId,
  refreshTeamMembers, // Function to refresh team members after adding
  refreshScrollTeam,
}) => {
  const [status, setStatus] = useState("idle"); // Status of adding members ('idle', 'success', 'error')
  const [isAdding, setIsAdding] = useState(false); // Spinner for adding member
  const { userId } = useAuth(); // Logged-in user ID from context
  const [page, setPage] = useState(0); // Pagination for teams
  const [selectedTeamId, setSelectedTeamId] = useState(""); // Selected team ID
  const [teams, setTeams] = useState([]); // List of available teams
  const [loading, setLoading] = useState(false); // Loading state for fetching teams
  const [hasMore, setHasMore] = useState(true); // Whether more teams are available
  const [isCoManager, setIsCoManager] = useState(false); // Co-manager checkbox state
  const [selectedUsers, setSelectedUsers] = useState([]); // Selected users for addition
  const selectTeamRef = useRef(null); // Reference to team dropdown for infinite scroll

  // Reset modal state
  const resetState = () => {
    setStatus("idle");
    setIsAdding(false);
    setPage(0);
    setSelectedTeamId("");
    setTeams([]);
    setLoading(false);
    setHasMore(true);
    setIsCoManager(false);
    setSelectedUsers([]);
  };

  // Fetch teams data on modal open
  useEffect(() => {
    if (visible) {
      resetState(); // Reset state when modal is opened
      fetchTeamsByPage(0); // Fetch the first page of teams
    }
  }, [visible]);

  // Fetch teams with pagination
  const fetchTeamsByPage = async (pageNum) => {
    if (loading || !hasMore) return; // Avoid duplicate calls

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=managed&page=${pageNum}`
      );
      const fetchedTeams = response.data.data.teamsList || [];
      const moreToCome = response.data.data.moreToCome;

      setHasMore(moreToCome);
      setTeams((prevTeams) => {
        const updatedTeams = [...prevTeams, ...fetchedTeams];

        // Set the default selected team if not already set
        if (!selectedTeamId && updatedTeams.length > 0) {
          setSelectedTeamId(updatedTeams[0]._id);
        }

        return updatedTeams;
      });
    } catch (error) {
      console.error("Error fetching paginated teams data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Infinite scrolling for teams dropdown
  const handleScroll = () => {
    if (!selectTeamRef.current || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = selectTeamRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      fetchTeamsByPage(page + 1);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleAddMember = async () => {
    if (!selectedTeamId || selectedUsers.length === 0) {
      setStatus("error");
      return;
    }

    setIsAdding(true);
    try {
      const body = isCoManager
        ? { makeComanagers: selectedUsers }
        : { addMembers: selectedUsers };

      await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${selectedTeamId}`,
        body
      );
      message.success("Member added successfully!");
      setStatus("success");
      resetState(); // Clear modal state
      onClose(); // Close modal
      if (refreshTeamMembers) refreshTeamMembers(); // Refresh team members list
      if (refreshScrollTeam) refreshScrollTeam(); // Refresh ScrollTeam
    } catch (error) {
      console.error("Error adding member:", error.response?.data || error.message);
      setStatus("error");
    } finally {
      setIsAdding(false);
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 border border-[#41EAD4] rounded-lg shadow-lg">
      <div className="relative bg-white rounded-lg max-w-[800px] max-h-[424px] p-8 shadow-lg overflow-y-auto">
        {/* Close Button */}
        <button
          onClick={() => {
            resetState(); // Clear modal state
            onClose();
          }}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <CloseOutlined className="text-xl" />
        </button>

        {/* Modal Title */}
        <div className="flex items-center gap-2 w-full mb-6">
          <h2 className="text-[#1B2028] font-semibold text-[30px] leading-[38px]">
            Add New Team Member
          </h2>
          <UserAddOutlined className="text-[#1B2028] w-[34px] h-[34px]" />
        </div>

        {status === "error" && (
          <div className="text-red-500 mb-4">
            Failed to add member. Please try again.
          </div>
        )}

        <div className="flex justify-between gap-6">
          {/* Member Search */}
          <div className="flex flex-col gap-2 w-1/2">
            <label className="text-sm font-medium text-gray-600">
              Search and Select Users
            </label>
            <MemberSearchSelect
              members={selectedUsers}
              onChange={(selectedMembers) => setSelectedUsers(selectedMembers)}
            />
          </div>

          {/* Select Team */}
          <div className="flex flex-col gap-2 w-1/2">
            {/* Label */}
            <label className="text-[#576271] font-medium text-[16px] leading-[24px]">
              Select Team
            </label>

            {/* Dropdown Container */}
            <div
              ref={selectTeamRef}
              onScroll={handleScroll}
              className="w-full border border-gray-300 rounded-lg p-2 text-[#192028] bg-white max-h-32 overflow-y-auto focus-within:ring-2 focus-within:ring-teal-400"
            >
              <select
                className="w-full bg-white border-none outline-none text-[14px] text-[#192028]"
                value={selectedTeamId}
                onChange={(e) => {
                  setSelectedTeamId(e.target.value);
                }}
                size={teams.length > 0 ? 5 : 1} // Show multiple rows if teams exist
              >
                {teams?.length > 0 ? (
                  teams.map((team) => (
                    <option
                      key={team._id}
                      value={team._id}
                      className={`text-[#192028] text-[14px] p-2 ${
                        selectedTeamId === team._id ? "bg-teal-100" : ""
                      }`}
                    >
                      {team.name}
                    </option>
                  ))
                ) : (
                  <option className="text-gray-400">No teams available</option>
                )}
              </select>
            </div>
          </div>
        </div>

        {/* Co-Manager Checkbox */}
        <div className="mt-4 flex items-center gap-2">
          <input
            type="checkbox"
            className="w-[24px] h-[24px] border-gray-300 rounded focus:ring-0 focus:outline-none"
            checked={isCoManager}
            onChange={(e) => setIsCoManager(e.target.checked)}
          />
          <label className="text-[#1B2028] font-normal text-[16px] leading-[24px]">
            Co-Manager
          </label>
        </div>

        {/* Modal Buttons */}
        <div className="flex justify-end gap-4 mt-8">
          {/* Close Button */}
          <button
            onClick={() => {
              resetState(); // Clear modal state
              onClose();
            }}
            className="flex flex-col justify-center items-center w-[318px] h-[40px] px-4 text-[#1B2028] border border-[#CAD4DD] rounded-lg bg-white shadow-sm focus:outline-none"
            style={{
              boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            Close
          </button>

          {/* Add Member Button */}
          <button
            onClick={handleAddMember}
            className={`flex flex-col justify-center items-center w-[318px] h-[40px] px-4 text-[#192028] bg-[#41EAD4] rounded-lg shadow-sm focus:outline-none ${
              isAdding || !selectedTeamId || selectedUsers.length === 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={isAdding || !selectedTeamId || selectedUsers.length === 0}
            style={{
              boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            {isAdding ? (
              <Spin size="small" className="text-[#192028]" />
            ) : (
              "Add Member"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewTeamMemberModal;
