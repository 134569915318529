
import React, { useState, useEffect } from 'react';
import VaultPinReset from '../../Services/SettingsTable/VaultPinReset';
import DeletedTeamDocuments from '../../Services/SettingsTable/DeletedTeamDocuments';
import TeamDeletionLog from '../../Services/SettingsTable/TeamDeletionLog';
import { Skeleton } from 'antd';

const Audit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedData, setDeletedData] = useState([]);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    // Simulate API calls for deleted documents and team data
    const fetchDeletedDocuments = async () => {
      try {
        // Replace with your API call
        const data = [
          {
            key: '1',
            documentName: 'Content',
            type: 'PDF',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            teams: 'Team Name',
            size: '10 MB',
            action: 'Restore',
          },
          {
            key: '2',
            documentName: 'Content',
            type: 'HTML',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            teams: 'Team XYZ Production.. +2',
            size: '20.1 MB',
            action: 'Restore',
          },
        ];
        setDeletedData(data);
      } catch (error) {
        console.error('Error fetching deleted documents:', error);
      }
    };

    const fetchTeamData = async () => {
      try {
        // Replace with your API call
        const data = [
          {
            key: '1',
            teamName: 'Team Name',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            action: 'Restore Team',
          },
          {
            key: '2',
            teamName: 'Another Team',
            deletedAt: '11/08/2028, 2:30 PM',
            deletedBy: 'Admin',
            action: 'Restore Team',
          },
        ];
        setTeamData(data);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    const fetchAllData = async () => {
      await Promise.all([fetchDeletedDocuments(), fetchTeamData()]);
      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  return (
    <div className="p-4">
      {/* Vault Pin Reset Section */}
      <div className="mb-8">
        <VaultPinReset />
      </div>

      {/* Deleted Team Documents Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Deleted Team Documents</h2>
        {isLoading ? (
          <div className="w-full">
            <Skeleton active />
          </div>
        ) : (
          <DeletedTeamDocuments isLoading={isLoading} deletedData={deletedData} />
        )}
      </div>

      {/* Team Deletion Log Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Team Deletion Log</h2>
        {isLoading ? (
          <div className="w-full">
            <Skeleton active />
          </div>
        ) : (
          <TeamDeletionLog isLoading={isLoading} teamData={teamData} />
        )}
      </div>
    </div>
  );
};

export default Audit;
