import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FilterOutlined,
  SortAscendingOutlined,
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Skeleton, Button, message } from "antd";
import ProfileDetail from "./ProfileDetail";
import AddNewTeamMemberModal from "../../../Services/Modal/AddNewTeamMember";
import MakeCoManagerModal from "../../../Services/EditModal/MakeCoManagerModal";
import RemoveCoManagerModal from "../../../Services/EditModal/RemoveCoManagerModal";
import RemoveFromTeamModal from "../../../Services/EditModal/RemoveFromTeamModal";
import { useAuth } from "../../../authcontext";
import KnowTeam from "./KnowTeam";
import ProfilePic from "../../../Services/UI/ProfilePic";
import AddTaskModal from '../../../Services/Modal/AddTaskModal'

const TeamMembers = ({
  memberIds,
  managerId,
  coManagerIds,
  teamId,
  type,
  refreshScrollTeam,
  teamName,
}) => {
  const { userId } = useAuth();
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [coManagerIdsState, setcoManagerIdsState] = useState(coManagerIds);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [sortAscending, setSortAscending] = useState(true);
  const [memberIdsState, setMemberIdsState] = useState(memberIds || []);
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCoManagerModalVisible, setIsCoManagerModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isRemoveFromTeamModalVisible, setIsRemoveFromTeamModalVisible] =
    useState(false);
  const [memberToAdd, setMemberToAdd] = useState(null);
  const [memberToMakeCoManager, setMemberToMakeCoManager] = useState(null);

  const [memberToRemove, setMemberToRemove] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [setCoManagerIdsState] = useState([]);

  const handleMemberRemoval = (removedMemberId, teamId) => {
    const updatedMemberIds = memberIdsState.filter(
      (id) => id !== removedMemberId
    );
    setMemberIdsState(updatedMemberIds); // Update the memberIds state
   fetchMembers();
  };

  const [knowTeamData, setKnowTeamData] = useState({
    managerId: null,
    coManagerIds: [],
    teamId: null,
  });

  const fetchMembers = async () => {
    setcoManagerIdsState(coManagerIds)
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
        { memberIds: [...memberIds, ...coManagerIdsState] } // Include co-manager IDs in the request
      );
      const fetchedMembers = response.data.data.memberDetails;
      setMembers(fetchedMembers);
      setFilteredMembers(fetchedMembers);
      console.log(fetchedMembers);
      // Update KnowTeam data
      setKnowTeamData({
        managerId: managerId,
        coManagerIds: coManagerIdsState,
        teamId: teamId,
      });
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  };

  const getHoverStyle = (item, hoveredItem, bgColor, textColor) => ({
    backgroundColor: hoveredItem === item ? bgColor : "#FFF",
    color: hoveredItem === item ? textColor : "#192028",
  });

  const fetchAfterRemovedMembers = async (
    updatedMemberIds = memberIdsState
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
        { memberIds: updatedMemberIds }
      );
      const fetchedMembers = response.data.data.memberDetails;
      setMembers(fetchedMembers);
      setFilteredMembers(fetchedMembers); // Update the filtered list
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLaterMembers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}/members`
      );

      const { comanagerId } = response.data.data;
      setcoManagerIdsState(comanagerId);
    } catch (error) {
      console.error("Error fetching updated members:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(members, filteredMembers);

  useEffect(() => {
    if (memberIds?.length > 0) {
      fetchMembers();
    }
  }, [memberIds]);

  useEffect(() => {
    // Reset the filter to "all" if there are any changes in key props
    setFilterType("all");
  }, [memberIds, managerId, coManagerIds, teamId, type, refreshScrollTeam, teamName]);
  
  useEffect(() => {
    // Reset the filter to "all" whenever a search is initiated
    if (searchQuery) {
      setFilterType("all");
    }
  }, [searchQuery]);
  


  const refreshTeamMembers = () => {
    fetchLaterMembers(); // Re-fetch the updated members list
  };

  // const fetchTeamMembers = () => {
  //   // After fetching, filter out the co-managers, if necessary
  //   setFilteredMembers(
  //     members.filter((member) => !coManagerIdsState.includes(member._id))
  //   );
  // };

  const handleMakeCoManager = (memberId) => {
    // Add the member to the coManagerIdsState
    setCoManagerIdsState((prev) => [...prev, memberId]);
    // Optionally re-fetch the members if necessary
    fetchTeamMembers();
  };

  const handleRemoveCoManager = (memberId) => {
    // Remove the member from the coManagerIdsState
    setCoManagerIdsState(coManagerIdsState.filter((id) => id !== memberId));
    // Optionally re-fetch the members
    fetchTeamMembers();
  };

  const fetchTeamMembers = async (ids = memberIds) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
        { memberIds: ids }
      );
      const fetchedMembers = response.data.data.memberDetails;
      setMembers(fetchedMembers);
      setFilteredMembers(fetchedMembers);
    } catch (error) {
      console.error("Error fetching team members:", error);
      message.error("Failed to fetch team members. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    let filtered = members.filter((member) => {
      // Filter logic based on the filter type
      if (filterType === "manager") return member._id === managerId;
      if (filterType === "coManager") return coManagerIdsState.includes(member._id);
      if (filterType === "member")
        return member._id !== managerId && !coManagerIdsState.includes(member._id);
      return true; // Show all members
    });
  
    // Apply search query if any
    if (searchQuery) {
      filtered = filtered.filter((member) =>
        `${member.name} ${member.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }
  
    // Sorting logic
    filtered.sort((a, b) => {
      const nameA = `${a.name} ${a.lastName}`.toLowerCase();
      const nameB = `${b.name} ${b.lastName}`.toLowerCase();
      return sortAscending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
  
    setFilteredMembers(filtered);
  }, [searchQuery, filterType, sortAscending, members, managerId, coManagerIdsState]);
  
  const handleNameClick = (member) => {
    setSelectedMember(member);
  };

  const handleMenuClick = (e, member) => {
    if (e.key === "makeCoManager") {
      setMemberToMakeCoManager(member);
      setIsCoManagerModalVisible(true);
    } else if (e.key === "removeCoManager") {
      setMemberToRemove(member);
      setIsRemoveModalVisible(true);
    } else if (e.key === "removeFromTeam") {
      setMemberToRemove(member);
      setIsRemoveFromTeamModalVisible(true);
    }
  };

  const renderMenu = (member) => {
    const isUserCoManager = coManagerIdsState.includes(userId);
  
    const items = isUserCoManager
      ? [
          {
            key: "removeFromTeam",
            icon: <TeamOutlined />,
            label: "Remove from Team",
          },
        ]
      : coManagerIdsState.includes(member._id)
      ? [
          {
            key: "removeCoManager",
            icon: <UserDeleteOutlined />,
            label: "Remove Co-Manager",
          },
          {
            key: "removeFromTeam",
            icon: <TeamOutlined />,
            label: "Remove from Team",
          },
        ]
      : [
          {
            key: "makeCoManager",
            icon: <UserAddOutlined />,
            label: "Make Co-Manager",
          },
          {
            key: "removeFromTeam",
            icon: <TeamOutlined />,
            label: "Remove from Team",
          },
        ];
  
    return (
      <Menu onClick={(e) => handleMenuClick(e, member)}>
        {items.map(({ key, icon, label }) => (
          <Menu.Item
            key={key}
            icon={icon}
            style={getHoverStyle(key, hoveredItem, "#D1F8F0", "#1B2028")}
            onMouseEnter={() => setHoveredItem(key)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  // Define handleTaskCancel to close the AddTaskModal
const handleTaskCancel = () => {
  setIsTaskModalVisible(false);
};

// Define handleTaskCreated to handle the task creation event
const handleTaskCreated = (taskData) => {
  message.success("Task created successfully!"); // Show success message
  setIsTaskModalVisible(false); // Close the modal after task creation
  // Optionally, refresh the team members or perform other actions
};

  
  return (
    <div className="relative w-full">
      {/* Flex container to align KnowTeam and Team Members in one row */}
      <div className="flex justify-between mb-12">
        <div
          className="w-[48%]"
          style={{
            display: "flex",
            Width: "398px",
            Height: "413px",
            padding: "4px",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            flexShrink: "0",
            borderRadius: "8px",
            marginBottom: "12px",
          }}
        >
          {/* KnowTeam */}
          <KnowTeam
            hasTeams={!!members.length}
            managerId={managerId}
            coManagerIds={
              coManagerIdsState.length === 0 ? coManagerIds : coManagerIdsState
            }
            teamId={teamId}
            refreshScrollTeam={refreshScrollTeam}
            refreshTeamMembers={refreshScrollTeam}
          />
        </div>

        <div className="relative w-full mb-12">
          {/* Add New Member Button */}
          <div className="flex justify-end ">
            {type === "managed" && (
              <>
               <button
        className="flex items-center gap-2 px-4 py-2 border-2 border-[#FF6F61] text-[#FF6F61] rounded-lg bg-white shadow-sm mt-9 mr-2"
        onClick={() => setIsTaskModalVisible(true)} // State for AddTaskModal
      >
        <PlusOutlined />
        Create Task
      </button>


              <button
                className="flex items-center gap-2 px-4 py-2 border-2 border-[#FF6F61] text-[#FF6F61] rounded-lg bg-white shadow-sm mt-9"
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined />
                Add New Member
              </button>
              </>
            )}
          </div>

          <div
            className="flex flex-col w-[860px] p-4 rounded-[8px] bg-white shadow-sm m-8 mb-12"
            style={{
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Search, Filter, and Heading */}
            <div className="flex justify-between items-center mb-4 gap-10">
              <h2 className="text-[20px] font-semibold leading-[28px] text-[#192028]">
                Team Members
              </h2>

              <div className="flex items-center gap-4">
                <div
                  className="flex items-center w-80 h-8 px-3 gap-2 rounded-md border border-gray-300 bg-white"
                  style={{
                    borderRadius: "6px",
                  }}
                >
                  <SearchOutlined
                    style={{ color: "#AEB8CA", fontSize: "16px" }}
                  />
                  <input
                    type="text"
                    placeholder="Search for team member"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full bg-transparent text-gray-500 outline-none placeholder-gray-400"
                  />
                </div>
              </div>

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="all"
                      style={{
                        padding: "8px 16px",
                        backgroundColor:
                          hoveredItem === "all" ? "#D1F8F0" : "#FFF",
                        color: hoveredItem === "all" ? "#1B2028" : "#192028",
                      }}
                      onMouseEnter={() => setHoveredItem("all")}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType("all")}
                    >
                      All
                    </Menu.Item>
                    <Menu.Item
                      key="manager"
                      style={{
                        padding: "8px 16px",
                        backgroundColor:
                          hoveredItem === "manager" ? "#D1F8F0" : "#FFF",
                        color:
                          hoveredItem === "manager" ? "#1B2028" : "#192028",
                      }}
                      onMouseEnter={() => setHoveredItem("manager")}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType("manager")}
                    >
                      Manager
                    </Menu.Item>
                    <Menu.Item
                      key="coManager"
                      style={{
                        padding: "8px 16px",
                        backgroundColor:
                          hoveredItem === "coManager" ? "#D1F8F0" : "#FFF",
                        color:
                          hoveredItem === "coManager" ? "#1B2028" : "#192028",
                      }}
                      onMouseEnter={() => setHoveredItem("coManager")}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType("coManager")}
                    >
                      Co-Manager
                    </Menu.Item>
                    <Menu.Item
                      key="member"
                      style={{
                        padding: "8px 16px",
                        backgroundColor:
                          hoveredItem === "member" ? "#D1F8F0" : "#FFF",
                        color: hoveredItem === "member" ? "#1B2028" : "#192028",
                      }}
                      onMouseEnter={() => setHoveredItem("member")}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType("member")}
                    >
                      Member
                    </Menu.Item>
                  </Menu>
                }
              >
                <button className="flex items-center gap-2 border px-4 py-2 rounded-lg">
                  Filter <FilterOutlined />
                </button>
              </Dropdown>

              <button
                className="flex items-center gap-2 border px-4 py-2 rounded-lg"
                onClick={() => setSortAscending(!sortAscending)}
              >
                Sort by Name <SortAscendingOutlined />
              </button>
            </div>

            {/* Members List */}
            {/* Members List */}
            <div className="overflow-auto" style={{ maxHeight: "400px" }}>
              {loading ? (
                [...Array(4)].map((_, index) => (
                  <div key={index} className="flex items-center gap-3 py-3">
                    <Skeleton.Avatar active size={40} />
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: 100 }}
                    />
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: 150 }}
                    />
                  </div>
                ))
              ) : (
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="text-left py-3 px-4">Name</th>
                      <th className="text-left py-3 px-4">Email</th>
                      <th className="text-left py-3 px-4">Department</th>
                      {type !== "joined" && <th className="py-3 px-4"></th>} {/* Hide column for 'joined' */}
                    </tr>
                  </thead>

                  <tbody>
  {filteredMembers.map((member, index) => (
    <tr key={index}>
      <td className="py-3 px-4 flex items-center gap-2">
        {member.avatar ? (
          <img
            src={member.avatar}
            alt={member.name}
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <ProfilePic name={member.name} />
        )}
        <div className="flex items-center gap-2">
          <span
            onClick={() => handleNameClick(member)}
            className="cursor-pointer text-[#192028] font-medium hover:underline"
          >
            {member.name} {member.lastName}
          </span>
          {member._id === managerId ? (
            <span className="flex items-center justify-center w-[63px] h-[20px] bg-[#D1F8F0] text-[#1B2028] text-[12px] font-semibold rounded-[6px]">
              Manager
            </span>
          ) : coManagerIdsState.includes(member._id) ? (
            <span className="flex items-center justify-center w-[85px] h-[20px] bg-[#D1F8F0] text-[#1B2028] text-[12px] font-semibold rounded-[6px]">
              Co-Manager
            </span>
          ) : null}
        </div>
      </td>
      <td className="py-3 px-4">{member.email}</td>
      <td className="py-3 px-4">{member?.userDetails?.department || "N/A"}</td>
      {type !== "joined" && ( // Hide this column for 'joined'
        <td className="py-3 px-4 text-right">
          {member._id !== managerId &&
            (!coManagerIdsState.includes(userId) ||
              !coManagerIdsState.includes(member._id)) && (
              <Dropdown overlay={renderMenu(member)} trigger={["click"]}>
                <MoreOutlined className="cursor-pointer" />
              </Dropdown>
            )}
        </td>
      )}
    </tr>
  ))}
</tbody>


                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedMember && (
        <ProfileDetail
          profile={selectedMember}
          teamId={teamId}
          onClose={() => setSelectedMember(null)}
        />
      )}
      <AddNewTeamMemberModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        teamId={teamId}
        // userId={memberToAdd?._id}
        userName={memberToRemove?.name}
        refreshScrollTeam={refreshScrollTeam}
        refreshTeamMembers={refreshScrollTeam}
        onSuccess={() => fetchLaterMembers(teamId)}
      />
      <MakeCoManagerModal
        visible={isCoManagerModalVisible}
        onClose={() => setIsCoManagerModalVisible(false)}
        teamId={teamId}
        userId={memberToMakeCoManager?._id}
        userName={memberToMakeCoManager?.name}
        refreshTeamMembers={refreshTeamMembers}
        onSuccess={() => fetchLaterMembers(teamId)}
      />

      <RemoveFromTeamModal
        visible={isRemoveFromTeamModalVisible}
        onClose={() => setIsRemoveFromTeamModalVisible(false)}
        teamId={teamId}
        userId={memberToRemove?._id}
        userName={memberToRemove?.name}
        refreshScrollTeam={refreshScrollTeam}
        refreshTeamMembers={refreshScrollTeam}
        onSuccess={() => fetchLaterMembers(teamId)}
      />

      <RemoveCoManagerModal
        visible={isRemoveModalVisible}
        onClose={() => setIsRemoveModalVisible(false)}
        teamId={teamId}
        userId={memberToRemove?._id}
        userName={memberToRemove?.name}
        refreshTeamMembers={refreshScrollTeam}
        onSuccess={() => fetchLaterMembers(teamId)} // Trigger fetch later members on success
      />

<AddTaskModal
        isVisible={isTaskModalVisible}
        handleCancel={handleTaskCancel}
        userId={userId}
        onTaskCreated={handleTaskCreated}
        keyword={"teams"}
      />
    </div>
  );
};

export default TeamMembers;