

import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import AddTeamModal from '../../Services/Modal/AddTeamModal';
import { useAuth } from '../../authcontext';
import axiosDocInstance from '../../ApiServices/AxiosDocInstance';


const TeamsHeader = ({ onTabChange, setViewAll, onAddTeamSuccess }) => {
  const { userId } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [activeKey, setActiveKey] = useState('managed'); // State for active tab
  const [teams, setTeams] = useState([]); // State for teams list
  const { sessionToken } = useAuth(); // Access the sessionToken from auth context

  useEffect(() => {
    // Fetch data for the active tab
    const fetchTeams = async () => {
      try {
        const response = await axiosDocInstance.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${activeKey}`
        );
        setTeams(response.data.data.teamsList || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchTeams();
  }, [sessionToken, activeKey]);

  const showModal = () => {
    setIsModalVisible(true); // Show modal when button is clicked
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    onTabChange?.(key); // Optional tab change handler
  };

  const renderViewAllOrEmptyState = () => {
    if (teams.length > 0) {
      return (
        <button
          className="underline text-[#768090] text-sm"
          style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
          onClick={() => setViewAll(true)} // Trigger setViewAll to show the ViewAll component
        >
        
        </button>
      );
    }
    // return (
    //   <span className="text-[#768090] text-sm">No teams available</span>
    // );
  };

  return (
    <div className="p-4">
      <>
        {/* Header section */}
        <div className="flex justify-between items-center mb-2">
          <h1
            className="text-[#192028] font-semibold text-2xl"
            style={{ lineHeight: '28px', marginBottom: '0' }}
          >
            Teams
          </h1>
        </div>

        {/* Custom Tabs for Managed and Joined teams */}
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-4">
            <button
              onClick={() => handleTabChange('managed')}
              className={`font-semibold px-4 py-2 rounded ${
                activeKey === 'managed'
                  ? 'text-[#007768] border-b-2 border-[#007768]'
                  : 'text-[#59616E]'
              }`}
            >
              Managed Team
            </button>
            <button
              onClick={() => handleTabChange('joined')}
              className={`font-semibold px-4 py-2 rounded ${
                activeKey === 'joined'
                  ? 'text-[#007768] border-b-2 border-[#007768]'
                  : 'text-[#59616E]'
              }`}
            >
              Joined
            </button>
          </div>

          {/* Create New Team Button, visible only on Managed team tab */}
          {activeKey === 'managed' && (
            <Button
              type="primary"
              className="create-new-team-btn"
              style={{
                display: 'flex',
                width: '180px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '8px',
                border: '1px solid #FF6F61',
                background: '#FF6F61',
                color: '#FFFFFF',
              }}
              onClick={showModal} // Show modal when button is clicked
            >
              + Create New Team
            </Button>
          )}
        </div>

        {/* Render view all or empty state */}
        <div className="flex justify-between items-center mb-2">
          <h2
            className="text-lg font-semibold text-[#192028]"
            style={{ lineHeight: '24px', marginTop: '0px' }}
          >
            {activeKey === 'managed'
              ? ''
              : ''}
          </h2>

          {renderViewAllOrEmptyState()}
        </div>

        {/* AddTeamModal component */}
        <AddTeamModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          refreshTeams={onAddTeamSuccess} // Pass the refresh function
        />
      </>
    </div>
  );
};

export default TeamsHeader;