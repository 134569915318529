import React from 'react';
import { TeamOutlined } from '@ant-design/icons';

const TeamsEmptyState = ({ type }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-4 w-[363px] h-[210px] rounded-md border border-[#4ED2BF] shadow-sm bg-white">
      {/* Centered Icon Container */}
      <div className="flex justify-center items-center w-[60px] h-[60px] border-[1.5px] border-[#C4CBD8] rounded-[12px] bg-white">
        <TeamOutlined className="text-[#C4CBD8] text-2xl" />
      </div>

      {/* Centered Text */}
      <div className="text-[#1B2028] text-[16px] font-semibold text-center leading-6">
        {type === 'managed'
          ? 'Team You create will be displayed here'
          : "Teams you've have been added into will appear here"}
      </div>

      {/* Optional Subtext */}
      {type === 'managed' && (
        <div className="text-[#59616E] text-center text-[14px] font-normal leading-[20px] underline">
          Created Now
        </div>
      )}
    </div>
  );
};

export default TeamsEmptyState;