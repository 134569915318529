import React from 'react';
import { Modal, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

const MakeCoManagerModal = ({
  visible,            // Boolean to control modal visibility
  onClose,            // Function to close the modal
  teamId,             // Team ID for the API request
  userId,             // User ID of the member to be made co-manager
  userName,           // Name of the member to be displayed
  refreshTeamMembers, // Function to refresh the team members list
}) => {
  /**
   * Function to handle making a team member a co-manager
   */
  const handleMakeCoManager = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`;
      const requestBody = {
        makeComanagers: [userId],
      };
  
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to make co-manager');
      }
  
      // Success: Notify user and refresh members
      message.success(`${userName} is now a co-manager!`);
      onClose(); // Close the modal
      if (refreshTeamMembers) {
        refreshTeamMembers(); 
      }
    } catch (error) {
      message.error(error.message || 'An error occurred. Please try again.');
    }
  };
  

  return (
    <Modal
      title={null} // Remove default title
      visible={visible} // Control modal visibility
      onCancel={onClose} // Close modal on cancel
      footer={null} // Remove default footer
      centered // Center the modal
      bodyStyle={{
        padding: '18px',
        textAlign: 'center',
      }}
      className="rounded-lg"
    >
      {/* Modal Header */}
      <div className="flex flex-col items-center justify-center mb-6">
        <div className="text-lg font-semibold flex items-center justify-center gap-2 mb-2">
          Make Co-Manager
          <UserAddOutlined />
        </div>
      </div>

      {/* Modal Description */}
      <div className="text-gray-500 mb-6">
        Are you sure you want to make <span className="font-bold">{userName}</span> a co-manager? <br />
        You're granting them full control over the team's membership. This means they can add or remove members as needed.
      </div>

      {/* Modal Actions */}
      <div className="flex justify-end gap-4">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="bg-gray-200 text-[#1B2028] rounded-lg py-2 px-6 hover:bg-gray-300 focus:outline-none"
        >
          Close
        </button>
        {/* Create Button */}
        <button
          onClick={handleMakeCoManager}
          className="bg-teal-300 text-[#192028] rounded-lg py-2 px-6 hover:bg-teal-400 focus:outline-none"
        >
          Create
        </button>
      </div>
    </Modal>
  );
};

export default MakeCoManagerModal;
