import React, { useState, useEffect,useRef } from "react";
import {
  Select,
  DatePicker,
  TimePicker,
  message,
  Checkbox,
  Input,
  Spin,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  CloseOutlined,DownOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useAuth } from "../../authcontext";
import axiosDocInstance from "../../ApiServices/AxiosDocInstance";
import dayjs from "dayjs";

const { Option } = Select;
const { TextArea } = Input;

const AddTaskModal = ({ isVisible, handleCancel, onTaskCreated, keyword }) => {
  // console.log(onTaskCreated);
  const { userId, userName } = useAuth();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    dueDate: null,
    // reminderDate: null,
    reminder: null,
    priority: "medium",
    team: "",
    assignee: "",
  });

  const [userTeams, setUserTeams] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskNameCount, setTaskNameCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [selfAssign, setSelfAssign] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});

  const [teamNamePage, setTeamNamePage] = useState(1);
  const [teamNameIsMore, setTeamNameIsMore] = useState(false);

  useEffect(() => {
    if (isVisible) fetchTeams();
  }, [isVisible]);

  const fetchTeams = async () => {
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names`
      );
      setUserTeams(response.data.data.teams || []);
      response.data?.data?.moreToCome && setTeamNameIsMore(response.data.data.moreToCome)

    } catch {
      message.error("Failed to fetch teams.");
    }
  };


  const fetchMoreUserTeams = async () => {
    if (!teamNameIsMore) return;

    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=${teamNamePage}`
      );
      const teams = response.data.data.teams;

      setUserTeams((prevTeams) => [...prevTeams, ...teams]); // Append new teams

      if (response.data?.data?.moreToCome) {
        setTeamNamePage((prevPage) => prevPage + 1);
      } else {
        setTeamNameIsMore(false); // No more teams to fetch
      }
    } catch (error) {
      console.error("Error fetching user teams:", error);
      message.error("Failed to fetch more teams.");
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const FilterSelectTeam = ({ label, value, onChange, options, onScrollEnd, isScrollable }) => {
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);
  console.log({isOpen});
  
  
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight-1 && onScrollEnd) {
        setIsOpen(true); 
        onScrollEnd(); // Trigger the callback when scrolled to the bottom
      }
    };
  
    useEffect(() => {
      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]);
  
    return (
      <div
        className="relative"
        ref={containerRef}
        style={{ width: "auto", minWidth: "200px" }}
      >
        <div
          className="px-4 py-2 border rounded-md bg-white text-black cursor-pointer flex justify-between items-center"
          onClick={toggleDropdown}
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <span>
            {value ? options.find((opt) => opt._id === value)?.name || label : label}
          </span>
          <DownOutlined />
        </div>
        {isOpen && (
          <div
            className="absolute z-50 bg-white border rounded-md shadow-lg mt-2"
            style={{
              maxHeight: isScrollable ? "150px" : "auto",
              overflowY: isScrollable ? "auto" : "visible",
              width: "100%",
            }}
            onScroll={isScrollable ? handleScroll : undefined}
            ref={dropdownRef}
          >
            <div>
              <div
                key=""
                className="px-4 py-2 hover:bg-[#D1F8F0] cursor-pointer"
                onClick={() => {
                  onChange({ target: { value: "" } });
                  setIsOpen(false);
                }}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {label}
              </div>
              {options.map((option) => (
                <div
                  key={option._id}
                  className="px-4 py-2 hover:bg-[#D1F8F0] cursor-pointer"
                  onClick={() => {
                    onChange({ target: { value: option._id } });
                    setIsOpen(false);
                  }}
                  style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const validateFields = () => {
    const fieldErrors = {};
    console.log(formData.name);

    if (!formData.name.trim()) fieldErrors.name = "* Task name is required.";
    if (!formData.dueDate) fieldErrors.dueDate = "* Due date is required.";
    if (formData.team && !formData.assignee) fieldErrors.assigneeErr = "* Select an Assignee.";
    if (
      formData.reminder &&
      moment(formData.reminder).isAfter(formData.dueDate)
    )
      fieldErrors.reminder = "Reminder must be before the due date.";
    console.log(fieldErrors);

    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "description") setDescriptionCount(value.length);
  };

  const handleTaskNameChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, name: value }));
    setTaskNameCount(value.length);
    setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
  };

  // const handleDateChange = (name, date) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: date ? date.toISOString() : null,
  //   }));
  // };

  const handleDateChange = (name, date) => {
    setFormData((prev) => {
      // Create a copy of the current formData
      const newFormData = {
        ...prev,
        [name]: date ? date.toISOString() : null,
      };

      // If dueDate changes and reminder exists, adjust reminder if needed
      if (name === "dueDate" && newFormData.reminder) {
        const reminder = new Date(newFormData.reminder);
        const dueDate = date ? new Date(date) : null;

        if (dueDate && reminder > dueDate) {
          newFormData.reminder = dueDate.toISOString();
          // handleReminderDateChange()
        }
      }

      return newFormData;
    });
  };





  // const handleReminderDateChange = (date) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     reminderDate: date ? date.toISOString() : null,
  //   }));
  // };


  const handleReminderDateChange = (date) => {
    if (date) {
      setFormData((prev) => ({
        ...prev,
        reminder: date.toISOString(), // Convert date to ISO format
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        reminder: null,
      }));
    }
  };

  const disabledReminderDates = (current) => {
    const today = new Date().setHours(0, 0, 0, 0); // Start of today
    const dueDate = formData.dueDate ? new Date(formData.dueDate) : null;

    // Disable dates before today or after dueDate (or today if dueDate is null)
    return (
      (current && current < today) || // Disable dates before today
      (dueDate ? current > dueDate : current > today) // Disable dates after dueDate or today
    );
  };

  const handleTeamChange = (teamId) => {
    // setSelfAssign(false)
    const selectedTeam = userTeams.find((team) => team._id === teamId);
    setFormData((prev) => ({
      ...prev,
      team: teamId,
    }));

    if (!selfAssign) {
      setAssigneeList(selectedTeam?.members || []);
      setFormData((prev) => ({
        ...prev,
        assignee: "",
      }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, assigneeErr: '' }));

  };

  const handleSelfAssignChange = (e) => {
    // setFormData((prev) => ({ ...prev, team: '' }))
    setErrors((prevErrors) => ({ ...prevErrors, assigneeErr: '' }));
    setSelfAssign(e.target.checked);
    setFormData((prev) => ({
      ...prev,
      assignee: e.target.checked ? userId : "",
    }));
    setAssigneeList([]);
    if (!e.target.checked) {
      const selectedTeam = userTeams.find((team) => team._id === formData.team);
      setAssigneeList(selectedTeam?.members || []);
    }

  };


  // const handleCreateTask = async () => {
  //   if (!validateFields()) return;
  //   setLoading(true);

  //   const payload = Object.keys(formData).reduce((acc, key) => {
  //     if (formData[key] !== "" && formData[key] !== null) {
  //       acc[key] = formData[key];
  //     }
  //     return acc;
  //   }, {});

  //   try {
  //     await axiosDocInstance.post(
  //       `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks`,
  //       payload
  //     );
  //     message.success("Task created successfully");
  //     if (onTaskCreated) onTaskCreated(); // Trigger refresh in parent
  //     handleCancel(); // Close the modal
  //   } catch {
  //     message.error("Failed to create task");
  //   } finally {
  //     setLoading(false);
  //   }
  // };




  const handleCreateTask = async () => {
    if (!validateFields()) return;
    setLoading(true);

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== "" && formData[key] !== null) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    try {
      await axiosDocInstance.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks`,
        payload
      );
      message.success("Task created successfully");
      if (onTaskCreated) {
        console.log("Triggering onTaskCreated callback");
        onTaskCreated(); // Ensure this is invoked
      }
      handleCancel(); // Close the modal
    } catch (error) {
      message.error("Failed to create task");
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (!isVisible) {
      setFormData({
        name: "",
        description: "",
        dueDate: null,
        // reminderDate: null,
        reminder: null,
        priority: "medium",
        team: "",
        assignee: "",
      });
      setTaskNameCount(0);
      setDescriptionCount(0);
      setSelfAssign(false);
      setErrors({});
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="relative flex flex-col w-[900px] max-h-[650px] p-12 bg-white  rounded-lg shadow-lg">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={handleCancel}
        >
          <CloseOutlined />
        </button>
        <div className="flex flex-col items-center gap-2">
          {isEditing ? (
            <Input
              value={formData.name}
              onChange={handleTaskNameChange}
              maxLength={30}
              autoFocus
              onBlur={() => setIsEditing(false)}
              className="text-center font-bold w-3/5 focus:outline-none focus:ring-0 focus:border-black"
            />
          ) : (
            <div className="flex items-center gap-2 cursor-pointer">
              <h2
                className="text-2xl font-semibold"
                onClick={() => setIsEditing(true)}
              >
                {formData.name || "Add Task Name"}
              </h2>
              <EditOutlined onClick={() => setIsEditing(true)} />
            </div>
          )}
          <span className="text-sm text-gray-500">{`${taskNameCount}/30`}</span>
          <span className="text-red-500 text-sm">{errors.name}</span>

        </div>

        {/* Self-Assign Checkbox */}
{keyword !== "teams" && (
  <div className="w-full flex justify-end">
    <Checkbox
      checked={selfAssign}
      onChange={handleSelfAssignChange}
      className="ml-4 text-base"
    >
      Self Assign
    </Checkbox>
  </div>
)}


        <div className="w-full mt-6">
          {/* Task Description */}
          <div className="flex justify-between items-center ">
            <label className="block text-gray-600">
              Task Description <span className="text-gray-400">(Optional)</span>
            </label>
            <span className="text-sm text-gray-500">{`${descriptionCount}/100`}</span>
          </div>
          <Input
            // rows={3}
            maxLength={100}
            value={formData.description}
            onChange={handleInputChange}
            name="description"
            className="w-full"
          />
        </div>

        {/* Due Date */}
        <div className="w-full mt-6">
          <label className="block text-gray-600">Select Due Date*</label>
          <DatePicker
            onChange={(date) => {
              handleDateChange("dueDate", date)
              setErrors((prevErrors) => ({ ...prevErrors, dueDate: '' }));
            }
            }
            suffixIcon={<CalendarOutlined />}
            className="w-full"
            inputReadOnly
            disabledDate={(current) => current && current < moment().startOf('day')}
          />
          <span className="text-red-500 text-sm">{errors.dueDate}</span>
        </div>

        {/* Team and Assigned to in One Row */}
        <div className="flex justify-between gap-6 mt-6">
          <div className="w-1/2">
            <label className="block text-gray-600">
              Team <span className="text-gray-400">(Optional)</span>
            </label>
            {/* <Select
              value={formData.team}
              onChange={handleTeamChange}
              className="w-full"
              placeholder="Select a team"
            >
              {userTeams.map((team) => (
                <Option key={team._id} value={team._id}>
                  {team.name}
                </Option>
              ))}
            </Select> */}

<FilterSelectTeam
    label="Select a team"
    value={formData.team}
    onChange={(e) => handleTeamChange(e.target.value)} // Update the team in formData
    options={userTeams} // Pass userTeams as options
    onScrollEnd={fetchMoreUserTeams} // Trigger fetchMoreUserTeams on scroll end
    isScrollable={true} // Enable scrolling
  />
          </div>
          <div className="w-1/2">
            <label className="block text-gray-600">Assigned to</label>
            {/* <Select
              value={formData.assignee}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, assignee: value }))
              }
              className="w-full"
              placeholder="Select an assignee"
              disabled={!formData.team}
            >
              {assigneeList.map((member) => (
                <Option key={member._id} value={member._id}>
                  {member.name || "Unnamed Member"}
                </Option>
              ))}
            </Select> */}

            <Select
              value={formData.assignee}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, assignee: value }))
              }
              className="w-full"
              placeholder="Select an assignee"
              disabled={!formData.team && !selfAssign}
            >
              {selfAssign && (
                <Option key="self-assign" value={userId}>
                  {userName}
                </Option>
              )}
              {assigneeList.map((member) => (
                <Option key={member._id} value={member._id}>
                  {member.name || "Unnamed Member"}
                </Option>
              ))}
            </Select>
            <span className="text-red-500 text-sm">{errors.assigneeErr}</span>

          </div>

        </div>

        {/* Mark Priority and Set a Reminder in One Row */}
        <div className="flex justify-between gap-6 mt-6">
          <div className="w-1/2">
            <label className="block text-gray-600">Mark Priority</label>
            <Select
              value={formData.priority}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, priority: value }))
              }
              className="w-full"
            >
              <Option value="high">
                <FlagOutlined style={{ color: "red", marginRight: "8px" }} />
                High
              </Option>
              <Option value="medium">
                <FlagOutlined style={{ color: "orange", marginRight: "8px" }} />
                Medium
              </Option>
              <Option value="low">
                <FlagOutlined style={{ color: "yellow", marginRight: "8px" }} />
                Low
              </Option>
            </Select>
          </div>

          {(selfAssign || formData.assignee == userId) ?

            <div className="w-1/2">
              <label className="block text-gray-600">
                Set a Reminder <span className="text-gray-400">(Optional)</span>
              </label>
              <div className="flex items-center gap-4">
                <DatePicker
                  value={formData.reminder ? dayjs(formData.reminder) : null}
                  onChange={handleReminderDateChange}
                  disabledDate={disabledReminderDates}
                  suffixIcon={<CalendarOutlined />}
                  className="w-full"
                  inputReadOnly
                />
                <TimePicker
                  onChange={(time) => handleDateChange("reminder", time)}
                  suffixIcon={<ClockCircleOutlined />}
                  className="w-full"
                  disabled={!formData.reminder}
                />
              </div>
              <span className="text-red-500 text-sm">{errors.reminder}</span>
            </div>
            :
            <div className="w-1/2">
            </div>

          }
          {/* <div className="w-1/2">
            <label className="block text-gray-600">
              Set a Reminder <span className="text-gray-400">(Optional)</span>
            </label>
            <div className="flex items-center gap-4">
              <DatePicker
               value={formData.reminder ? dayjs(formData.reminder) : null}
                onChange={handleReminderDateChange}
                disabledDate={disabledReminderDates}
                suffixIcon={<CalendarOutlined />}
                className="w-full"
              />
              <TimePicker
                onChange={(time) => handleDateChange("reminder", time)}
                suffixIcon={<ClockCircleOutlined />}
                className="w-full"
                disabled={!formData.reminder}
              />
            </div>
            <span className="text-red-500 text-sm">{errors.reminder}</span>
          </div> */}
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={handleCancel}
            className="px-4 py-2 text-[#1B2028] bg-[#C4CBD8] rounded-md"
          >
            Close
          </button>
          <button
            onClick={handleCreateTask}
            className={`px-6 py-2 text-[#192028] bg-[#41EAD4] rounded-md ${loading ? "cursor-not-allowed opacity-50" : ""
              }`}
            disabled={loading}
          >
            {loading ? <Spin size="small" /> : "Save"}
          </button>
        </div>
      </div>
    </div>

  );
};

export default AddTaskModal;

