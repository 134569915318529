import React from 'react';

const ProfilePic = ({ name, size = "md" }) => {
  const initial = name ? name.charAt(0).toUpperCase() : '?';

  // Map letters to colors
  const colorMap = {
    A: "bg-red-500",
    B: "bg-blue-500",
    C: "bg-green-500",
    D: "bg-yellow-500",
    E: "bg-purple-500",
    F: "bg-orange-500",
    G: "bg-pink-500",
    H: "bg-indigo-500",
    I: "bg-lime-500",
    J: "bg-amber-500",
    K: "bg-cyan-500",
    L: "bg-rose-500",
    M: "bg-fuchsia-500",
    N: "bg-sky-500",
    O: "bg-violet-500",
    P: "bg-emerald-500",
    Q: "bg-teal-500",
    R: "bg-slate-500",
    S: "bg-gray-500",
    T: "bg-stone-500",
    U: "bg-zinc-500",
    V: "bg-neutral-500",
    W: "bg-cool-gray-500",
    X: "bg-warm-gray-500",
    Y: "bg-blue-400",
    Z: "bg-red-400",
  };

  // Default to teal if the letter isn't in the map
  const bgColor = colorMap[initial] || "bg-teal-500";

  // Map sizes to dimensions
  const sizeMap = {
    xs: "w-8 h-8 text-sm",
    sm: "w-12 h-12 text-lg",
    md: "w-16 h-16 text-xl",
    lg: "w-20 h-20 text-2xl",
    xl: "w-28 h-28 text-3xl",
  };

  const sizeClasses = sizeMap[size] || sizeMap.md;

  return (
    <div
      className={`flex justify-center items-center rounded-full ${bgColor} text-white font-bold border-2 border-teal-400 ${sizeClasses}`}
    >
      {initial}
    </div>
  );
};

export default ProfilePic;
