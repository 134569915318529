

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TeamCard from '../../../Services/UI/TeamCard';
import EmptyTeamCard from '../../../Services/UI/EmptyState/EmptyTeamCard';
import AddNewTeamMemberModal from '../../../Services/Modal/AddNewTeamMember';
import { useAuth } from '../../../authcontext';
import { use } from 'react';

const ScrollTeam = ({ type, onTeamSelect, activeKey, setViewAll, refreshScrollTeam }) => {
  const { userId } = useAuth();
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardName, setSelectedCardName] = useState('');
  const containerRef = useRef(null);
  const [department, setDepartment] = useState("")

  // Fetch initial teams from API
  const fetchInitialTeams = async () => {
    setLoading(true);
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}`
      );
      const initialTeams = response1.data.data.teamsList;

      const response2 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}&page=1`
      );
      const pageOneTeams = response2.data.data.teamsList;

      const combinedTeams = [...initialTeams, ...pageOneTeams];
      setTeams(combinedTeams);

      if (combinedTeams.length === 0) {
        setHasMore(false);
      }

      if (initialTeams.length > 0) {
        const firstTeam = initialTeams[0];
        setSelectedCardId(firstTeam._id);
        setSelectedCardName(firstTeam.name);
        onTeamSelect({
          memberIds: firstTeam.members,
          managerId: firstTeam.managerId,
          coManagerIds: firstTeam.comanagerId,
          teamId: firstTeam._id,
        });
      }
    } catch (error) {
      console.error("Error fetching initial teams data:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamsByPage = async (pageNum) => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?type=${type}&page=${pageNum}`
      );
      const fetchedTeams = response.data.data.teamsList;

      if (fetchedTeams.length === 0) {
        setHasMore(false);
      } else {
        setTeams((prevTeams) => [...prevTeams, ...fetchedTeams]);
      }
    } catch (error) {
      console.error('Error fetching paginated teams data:', error);
    } finally {
      setLoading(false);
    }
  };


   // Refresh teams function
   const refreshTeams = () => {
    setPage(2); // Reset pagination
    fetchInitialTeams(); // Re-fetch the initial data
  };

  useEffect(() => {
    fetchInitialTeams();
  }, [userId, type]);

  useEffect(() => {
    if (refreshScrollTeam) {
      refreshScrollTeam.current = fetchInitialTeams;
    }
  }, [refreshScrollTeam]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;

      if (container) {
        if (
          container.scrollWidth - container.scrollLeft <=
          container.clientWidth + 50 &&
          hasMore &&
          !loading
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loading]);

  useEffect(() => {
    if (page > 2) {
      fetchTeamsByPage(page);
    }
  }, [page]);

  const handleDeleteTeam = (team) => {
    console.log(`Team ${team.name} deleted!`);
  };

  const handleAddTeamMember = (team) => {
    setSelectedTeam(team);
    setSelectedCardId(team._id);
    setSelectedCardName(team.name);
    setIsModalVisible(true);
  };

  const handleCardClick = (teamId, teamName, memberIds, managerId, coManagerIds) => {
    setSelectedCardId(teamId);
    setSelectedCardName(teamName);
    setSelectedTeam({ _id: teamId, name: teamName });
    onTeamSelect({ memberIds, managerId, coManagerIds, teamId });
  };

  const renderViewAllOrEmptyState = () => {
    if (teams.length > 0) {
      return (
        <button
          className="underline text-[#768090] text-sm"
          style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
          onClick={() => setViewAll(true)}
        >
          View All
        </button>
      );
    }
    // return <span className="text-[#768090] text-sm">No teams available</span>;
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2
          className="text-lg font-semibold text-[#192028]"
          style={{ lineHeight: '24px', marginTop: '0px' }}
        >
          {type === 'managed'
            ? 'Manage team member access and privileges'
            : 'Joined Teams'}
        </h2>
        {renderViewAllOrEmptyState()}
      </div>

      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          overflowX: 'auto',
          padding: '16px',
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'thin',
          scrollSnapType: 'x mandatory',
        }}
      >
        {!loading && teams.length === 0 ? (
          <EmptyTeamCard type={type} />
        ) : (
          teams.map((team) => (
            <div
              key={team._id}
              style={{
                scrollSnapAlign: 'start',
                flex: '0 0 auto',
                border:
                  selectedCardId === team._id
                    ? '2px solid var(--Teal-Light-Primary-Hover_Click, #4ED2BF)'
                    : 'none',
                borderRadius: selectedCardId === team._id ? '8px' : 'none',
              }}
              onClick={() =>
                handleCardClick(
                  team._id,
                  team.name,
                  team.members,
                  team.managerId,
                  team.comanagerId
                )
              }
            >
              <TeamCard
                key={team._id}
                team={team}
                onEditTeam={refreshTeams}
                onAddTeamSuccess={refreshTeams}
                onDeleteSuccess={refreshTeams}
                onDeleteTeam={() => handleDeleteTeam(team)}
                onLeaveTeam={refreshTeams}
                onAddTeamMember={() => handleAddTeamMember(team)}
              />
            </div>
          ))
        )}

        {loading &&
          Array(4)
            .fill()
            .map((_, index) => (
              <div
                key={index}
                style={{
                  width: '372px',
                  height: '210px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px',
                  boxSizing: 'border-box',
                  gap: '8px',
                  flexShrink: 0,
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              ></div>
            ))}

        {selectedTeam && (
          <AddNewTeamMemberModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            teamId={selectedCardId}
            teamName={selectedCardName}
            teams={teams}
            refreshSelectedTeam={refreshTeams}
          />
        
        )}

      </div>
    </>
  );
};

export default ScrollTeam;