import React, { useState } from "react";
import { Modal, Form, Button, Spin, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import MemberSearchSelect from "../Utils/MemberSearchSelect";

const AddCoManagerModal = ({
  visible,
  onClose,
  teamId,
  onCoManagerAdded, // Callback to notify the parent component
  refreshTeamMembers, // Optional: to trigger a refresh of team members
  refreshScrollTeam
}) => {
  const [form] = Form.useForm();
  const [isCreating, setIsCreating] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]); // Selected users for addition

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
  
      const convertIdsToString = (obj) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            // Assuming IDs are numeric, check and convert to string
            if (typeof obj[key] === 'number') {
              obj[key] = obj[key].toString();
            }
          }
        }
        return obj;
      };
  
      const updatedValues = convertIdsToString(values);
      const selectedUserId = updatedValues.username;
  
      setIsCreating(true);
  
      // Ensure makeComanagers is an array, not nested
      const makeComanagersPayload = selectedUserId;
  
      // Make an API call to add the co-manager
      const response = await fetch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({ makeComanagers: makeComanagersPayload }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add co-manager");
      }
  
      // Notify the user of success
      message.success("Co-Manager added successfully!");
      if (refreshTeamMembers) refreshTeamMembers(); // Refresh team members list
    if (refreshScrollTeam) refreshScrollTeam();  // **Refresh ScrollTeam**
  
   
      // Reset the form and close the modal
      form.resetFields();
      onClose();
  
      // Optional: Refresh team members if provided
      if (refreshTeamMembers) refreshTeamMembers();
    } catch (error) {
      // Handle any errors that occur
      message.error(error.message || "An error occurred. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };
  

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      bodyStyle={{
        padding: "40px 60px",
        borderRadius: "12px",
        backgroundColor: "#F9FAFB",
      }}
    >
      {/* Header */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-semibold text-[#1B2028] flex items-center justify-center">
          Add Co-Manager <UserAddOutlined className="ml-2 text-[#41EAD4]" />
        </h2>
        <p className="text-gray-500 text-sm">
          Select a user to assign as a co-manager to the team.
        </p>
      </div>

      {/* Form Section */}
      <Form form={form} layout="vertical" className="space-y-6">
        <Form.Item
          name="username"
          label={<span className="font-medium text-[#1B2028]">User Name</span>}
          rules={[{ required: true, message: "Please select a user!" }]}
        >
          <MemberSearchSelect
            members={selectedUsers}
            onChange={(selectedMembers) => setSelectedUsers(selectedMembers)}
          />
        </Form.Item>
      </Form>

      {/* Action Buttons */}
      <div className="flex justify-end mt-8 gap-3">
        <Button
          onClick={onClose}
          className="bg-gray-200 text-[#1B2028] h-10 px-5 rounded-md hover:bg-gray-300"
        >
          Close
        </Button>
        <Button
          type="primary"
          onClick={handleCreate}
          className="bg-[#41EAD4] text-white h-10 px-6 rounded-md hover:bg-teal-500"
          disabled={isCreating}
        >
          {isCreating ? <Spin size="small" /> : "Create"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddCoManagerModal;