
import { useState, useEffect } from "react";
import axios from "axios";

/**
 * Custom hook for debouncing and fetching paginated results.
 * @param {string} term - The term to debounce and fetch data for.
 * @param {number} page - The current page number for pagination.
 * @param {function} setPage - Function to update the page number.
 * @param {number} delay - The debounce delay in milliseconds (default: 500ms).
 * @returns {Object} - An object containing the debounced term, results, loading state, fetchMore function, and moreToCome flag.
 */
const useDebounceFetch = (term = "a", page, setPage, delay = 500) => {
  const [debouncedTerm, setDebouncedTerm] = useState(term.trim());
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moreToCome, setMoreToCome] = useState(true);

  // Debounce logic for the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(term.trim()); // Trim spaces before and after the term
    }, delay);

    return () => clearTimeout(handler); // Cleanup timeout on term or delay change
  }, [term, delay]);

  // Fetch data when debounced term or page changes
  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedTerm || loading) return; // Skip if no term or already loading

      setLoading(true);
      try {
        const term = debouncedTerm === '' ? 'a' : debouncedTerm;
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/search?term=${encodeURIComponent(term)}${
            page > 0 ? `&page=${page}` : ""
          }`
        );
        // Handle the response as needed
    
        const newUsers = response.data?.data?.users || [];
        setResults((prevResults) =>
          page === 0 ? newUsers : [...prevResults, ...newUsers]
        ); // Reset or append results
        setMoreToCome(response.data?.data?.moreToCome ?? false); // Update moreToCome flag
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedTerm, page]);

  // Reset results and pagination when term changes
  useEffect(() => {
    if (debouncedTerm !== term.trim()) {
      setResults([]); // Clear results for new search
      setPage(0); // Reset page for new search
      setMoreToCome(true); // Reset moreToCome for new search
    }
  }, [debouncedTerm, term, setPage]);

  // Function to fetch the next page of results
  const fetchMore = () => {
    if (!loading && moreToCome) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return { debouncedTerm, results, loading, fetchMore, moreToCome };
};

export default useDebounceFetch;
