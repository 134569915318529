
// import React, { useEffect, useRef, useState } from 'react';
// import { Tag, message } from 'antd';
// import axios from 'axios';
// import EmptyStateSvg from '../../../assets/SVG/Completed.svg'; // Import the provided empty state SVG

// // Skeleton Loader Component
// const LoadingPlaceholder = () => {
//   return (
//     <div className="flex justify-between items-center p-3 mb-2 rounded-lg bg-gray-100 shadow-sm relative animate-pulse">
//       <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />

//       <div className="flex flex-col justify-start gap-1 w-1/2">
//         <div className="flex items-center gap-2">
//           <div className="w-6 h-6 bg-gray-300 rounded-full flex-shrink-0" />
//           <div className="h-4 bg-gray-300 rounded w-3/4"></div>
//         </div>
//         <div className="ml-8 h-3 bg-gray-300 rounded w-1/2"></div>
//       </div>

//       <div className="flex justify-between w-1/2 gap-5">
//         <div className="h-4 bg-gray-300 rounded w-16"></div>
//         <div className="h-4 bg-gray-300 rounded w-16"></div>
//         <div className="h-4 bg-gray-300 rounded w-16"></div>
//         <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
//       </div>
//     </div>
//   );
// };

// const Completed = ({ tasks, setTasks, fetchNextPage, completedrefresh, triggerOngoingRefresh }) => {
//   const [ongoingRefresh, setOngoingRefresh] = useState(false);
//   const [page, setPage] = useState(1);
//   const containerRef = useRef(null);
//   const [loading, setLoading] = useState(true); // Add loading state

//   const updateTaskStatus = async (taskId) => {
//     try {
//       const response = await axios.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskId}`,
//         { markDone: false }
//       );

//       if (response.status === 200) {
//         message.success("Task marked as ongoing!");
//         setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
//         triggerOngoingRefresh(); // Refresh the Ongoing component
//       }
//     } catch (error) {
//       message.error("Failed to mark task as ongoing");
//     }
//   };

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
//       if (scrollTop + clientHeight >= scrollHeight) {
//         fetchNextPage(page + 1);
//         setPage(page + 1);
//       }
//     }
//   };

//   useEffect(() => {
//     setLoading(true); // Start loading
//     // Simulate fetching data to show loader
//     setTimeout(() => {
//       setLoading(false); // Stop loading after tasks are fetched
//     }, 1500); // Simulated delay for the loading state

//     if (containerRef.current && tasks.length > 0) {
//       containerRef.current.addEventListener('scroll', handleScroll);
//     }
//     return () => {
//       if (containerRef.current && tasks.length > 0) {
//         containerRef.current.removeEventListener('scroll', handleScroll);
//       }
//     };
//   }, [page, tasks]);

//   return (
//     <div className="p-5 bg-white w-full">
//       {/* Only add scroll if there are tasks */}
//       <div
//         ref={containerRef}
//         className={tasks.length > 0 ? "max-h-[300px] overflow-y-auto pr-2" : ""}
//       >
//         {loading
//           ? Array(5)
//               .fill()
//               .map((_, index) => <LoadingPlaceholder key={index} />) // Render loading placeholders while loading
//           : tasks.length > 0 ? (
//             tasks.map((task, index) => (
//               <div
//                 key={index}
//                 className="flex justify-between items-center p-3 mb-2 rounded-lg bg-gray-100 shadow-sm relative"
//               >
//                 <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />

//                 <div className="flex flex-col justify-start gap-1 w-1/2">
//                   <div className="flex items-center gap-2">
//                     <div
//                       onClick={() => updateTaskStatus(task._id)}
//                       className="w-6 h-6 bg-white border-2 border-gray-300 rounded-full flex justify-center items-center cursor-pointer"
//                     >
//                       <div className="w-3 h-3 bg-gray-400 rounded-full" />
//                     </div>
//                     <div className="font-semibold line-through">{task.name}</div>
//                   </div>
//                   <div className="text-gray-500 ml-8">
//                     Completed on {new Date(task.dueDate).toLocaleDateString()} at{' '}
//                     {new Date(task.dueDate).toLocaleTimeString([], {
//                       hour: '2-digit',
//                       minute: '2-digit',
//                     })}
//                   </div>
//                 </div>

//                 <div className="flex justify-between w-1/2 gap-5">
//                   <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
//                     {task.assigner || 'Unknown'}
//                   </Tag>
//                   <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
//                     {task.assignee || 'Unknown'}
//                   </Tag>
//                   <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
//                     {task.team || 'No Team'}
//                   </Tag>
//                   <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div
//               className="flex flex-col items-center justify-center mt-16"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 padding: "20px",
//               }}
//             >
//               <img
//                 src={EmptyStateSvg}
//                 alt="No Completed Tasks"
//                 style={{
//                   width: "463.878px",   // Exact width as per your request
//                   height: "259.975px",  // Exact height
//                   flexShrink: 0,        // Prevent shrinking
//                   opacity: 0.9,         // Optional opacity adjustment for better clarity
//                 }}
//                 className="mb-4"
//               />
//               <p
//                 className="text-lg font-semibold"
//                 style={{
//                   color: "var(--Teal-Light-Charcoal-Default, #1B2028)",
//                   textAlign: "center",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   lineHeight: "24px", // 150%
//                 }}
//               >
//                 No Completed Tasks
//               </p>
//               <p
//                 className="text-sm"
//                 style={{
//                   color: "var(--Teal-Light-Charcoal-Subtle, #59616E)",
//                   textAlign: "center",
//                   fontSize: "12px",
//                   fontWeight: 400,
//                   lineHeight: "20px", // 166.667%
//                 }}
//               >
//                 Tasks that have been marked completed will appear here.
//               </p>
//             </div>
//           )}
//       </div>
//     </div>
//   );
// };

// export default Completed;




























import React, { useEffect, useRef, useState } from 'react';
import { Tag, message } from 'antd';
import axios from 'axios';
import EmptyStateSvg from '../../../assets/SVG/Completed.svg'; // Import the provided empty state SVG

// Skeleton Loader Component
const LoadingPlaceholder = () => {
  return (
    <div className="flex justify-between items-center p-3 mb-2 rounded-lg bg-gray-100 shadow-sm relative animate-pulse">
      <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />

      <div className="flex flex-col justify-start gap-1 w-1/2">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 bg-gray-300 rounded-full flex-shrink-0" />
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        </div>
        <div className="ml-8 h-3 bg-gray-300 rounded w-1/2"></div>
      </div>

      <div className="flex justify-between w-1/2 gap-5">
        <div className="h-4 bg-gray-300 rounded w-16"></div>
        <div className="h-4 bg-gray-300 rounded w-16"></div>
        <div className="h-4 bg-gray-300 rounded w-16"></div>
        {/* <div className="w-6 h-6 bg-gray-300 rounded-full"></div> */}
      </div>
    </div>
  );
};

const Completed = ({ tasks, setTasks, fetchNextPage, completedrefresh, triggerOngoingRefresh }) => {
  const [ongoingRefresh, setOngoingRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const updateTaskStatus = async (taskId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskId}`,
        { markDone: false }
      );

      if (response.status === 200) {
        message.success("Task marked as ongoing!");
        setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
        triggerOngoingRefresh(); // Refresh the Ongoing component
      }
    } catch (error) {
      message.error("Failed to mark task as ongoing");
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        fetchNextPage(page + 1);
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    if (containerRef.current && tasks.length > 0) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current && tasks.length > 0) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, tasks]);

  //   return (
  //     <div className="p-5 bg-white w-full">
  //       <div
  //         ref={containerRef}
  //         className={tasks.length > 0 ? "max-h-[300px] overflow-y-auto pr-2" : ""}
  //       >
  //         {loading
  //           ? Array(5)
  //             .fill()
  //             .map((_, index) => <LoadingPlaceholder key={index} />)
  //           : tasks.length > 0 ? (
  //             tasks.map((task, index) => (
  //               <div
  //                 key={index}
  //                 className="flex justify-between items-center p-3 mb-2 rounded-lg bg-gray-100 shadow-sm relative"
  //               >
  //                 <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />

  //                 <div className="flex flex-col justify-start gap-1 w-1/2">
  //                   <div className="flex items-center gap-2">
  //                     <div
  //                       onClick={() => updateTaskStatus(task._id)}
  //                       className="w-6 h-6 bg-white border-2 border-gray-300 rounded-full flex justify-center items-center cursor-pointer"
  //                     >
  //                       <div className="w-3 h-3 bg-gray-400 rounded-full" />
  //                     </div>
  //                     <div
  //                       className="font-semibold line-through"
  //                       title={task.name}
  //                     >
  //                       {task.name.length > 20 ? `${task.name.substring(0, 20)}...` : task.name}
  //                     </div>
  //                   </div>
  //                   <div className="text-gray-500 ml-8">
  //                     Completed on {new Date(task.dueDate).toLocaleDateString()} at{' '}
  //                     {new Date(task.dueDate).toLocaleTimeString([], {
  //                       hour: '2-digit',
  //                       minute: '2-digit',
  //                     })}
  //                   </div>
  //                 </div>

  //                 <div className="flex justify-between items-center w-full">
  //                   {/* Tags Section */}
  //                   <div className="flex items-center gap-28 w-1/2">
  //                     <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
  //                       {task.assigner || 'Unknown'}
  //                     </Tag>
  //                     <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
  //                       {task.assignee || 'Unknown'}
  //                     </Tag>
  //                     <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
  //                       {task.team || 'No Team'}
  //                     </Tag>
  //                   </div>

  //                   {/* Circle Section */}
  //                   {/* <div className="w-6 h-6 bg-gray-300 rounded-full flex-shrink-0"></div> */}
  //                 </div>

  //               </div>
  //             ))
  //           ) : (
  //             <div className="flex flex-col items-center justify-center mt-16">
  //               <img
  //                 src={EmptyStateSvg}
  //                 alt="No Completed Tasks"
  //                 className="mb-4"
  //                 style={{
  //                   width: "463.878px",
  //                   height: "259.975px",
  //                   opacity: 0.9,
  //                 }}
  //               />
  //               <p className="text-lg font-semibold text-center">
  //                 No Completed Tasks
  //               </p>
  //               <p className="text-sm text-center text-gray-500">
  //                 Tasks that have been marked completed will appear here.
  //               </p>
  //             </div>
  //           )}
  //       </div>
  //     </div>
  //   );
  // };

  // export default Completed;




  return (
    <div className="p-5 bg-white w-full">
      <div
        ref={containerRef}
        className={tasks.length > 0 ? "max-h-[300px] overflow-y-auto pr-2" : ""}
      >
        {tasks.length > 0 ? (
          tasks.map((task, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-3 mb-2 rounded-lg bg-gray-100 shadow-sm relative"
            >
              <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />
              <div className="flex flex-col justify-start gap-1 w-1/2">
                <div className="flex items-center gap-2">
                  {((task.user_id === task.assigner_id) || (task.user_id === task.assignee_id)) ? (

                    <div
                      onClick={() => updateTaskStatus(task._id)}
                      className="w-6 h-6 bg-white border-2 border-gray-300 rounded-full flex justify-center items-center cursor-pointer"
                    >
                      <div className="w-3 h-3 bg-gray-400 rounded-full" />
                    </div>
                  ) : (
                    <div
                      className="w-6 h-6 "
                    >
                      {/* <div className="w-3 h-3 bg-gray-400 rounded-full" /> */}
                    </div>
                  )}
                  <div
                    className="font-semibold line-through"
                    title={task.name}
                  >
                    {task.name.length > 20 ? `${task.name.substring(0, 20)}...` : task.name}
                  </div>
                </div>
                <div className="text-gray-500 ml-8">
                  Completed on {new Date(task.dueDate).toLocaleDateString()} at{' '}
                  {new Date(task.dueDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
              </div>
              {/* <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-28 w-1/2">
                  <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
                    {task.assigner || 'Unknown'}
                  </Tag>
                  <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
                    {task.assignee || 'Unknown'}
                  </Tag>
                  <Tag className="px-4 py-1 rounded-full border border-gray-300 text-gray-900 bg-gray-100 text-center">
                    {task.team || 'No Team'}
                  </Tag>
                </div>
              </div> */}

              <div className="flex items-center space-x-12 relative mr-8 w-full justify-between">
                <div className="flex items-center justify-between gap-12 w-full max-w-[600px]">
                  <div className="flex items-center justify-center px-6 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700 w-[150px] truncate">
                    {task.assigner || "Unknown"}
                  </div>
                  <div className="flex items-center justify-center px-6 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700 w-[150px] truncate">
                    {task.assignee || "Unknown"}
                  </div>
                  <div className="flex items-center justify-center px-6 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700 w-[150px] truncate">
                    {task.team || "No Team"}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center mt-16">
            <img
              src={EmptyStateSvg}
              alt="No Completed Tasks"
              className="mb-4"
              style={{
                width: "463.878px",
                height: "259.975px",
                opacity: 0.9,
              }}
            />
            <p className="text-lg font-semibold text-center">
              No Completed Tasks
            </p>
            <p className="text-sm text-center text-gray-500">
              Tasks that have been marked completed will appear here.
            </p>
          </div>
        )}

      </div>
    </div>
  );
};

export default Completed;

















