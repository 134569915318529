

import React, { useState } from 'react';
import ScrollTeam from './Common/ScrollTeam';
import KnowTeam from './Common/KnowTeam';
import TeamMembers from './Common/TeamMembers';
import ViewAll from './Common/ViewAll';

const Joined = ({ viewAll, setViewAll }) => {
  const [memberIds, setMemberIds] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [coManagerIds, setCoManagerIds] = useState([]);
  const [teamId, setTeamId] = useState(null);


  const handleTeamSelect = ({ memberIds, managerId, coManagerIds, teamId }) => {
    setTeamId(teamId);
    setMemberIds(memberIds);
    setManagerId(managerId);
    setCoManagerIds(coManagerIds);
    
  };

  const hasTeams = memberIds.length > 0;

  return (
    <div>
      {!viewAll ? (
        <div className="p-4">
          <div>
            <ScrollTeam type="joined" onTeamSelect={handleTeamSelect}  setViewAll={setViewAll}/>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* <div className="md:col-span-1">
              <KnowTeam hasTeams={hasTeams} managerId={managerId} coManagerIds={coManagerIds} teamId={teamId}/>
            </div> */}
            <div className="md:col-span-2">
              <TeamMembers memberIds={memberIds} managerId={managerId} coManagerIds={coManagerIds} teamId={teamId} type="joined"/>
            </div>
          </div>
        </div>
      ) : (
        <ViewAll type="joined" />
      )}
    </div>
  );
};

export default Joined;